import "../styles/AuthPage.css";
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import headerImage from "../assets/debate_icon.jpg";
import { handleLogin, handleGoogleSignIn } from '../queries/Queries'; 
import googleLogo from "../images/google_logo.png";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    console.log('Attempting login with:', email);
    handleLogin(email, password)
      .then(result => {
        console.log('Login result:', result);
        if (result === false || result == null) {
          setError('Login failed. Please check your credentials.');
        } else {
          console.log('Login successful:', result);
          navigate('/');
        }
      })
      .catch(err => {
        console.error('Login error:', err);
        setError('An error occurred during login. Please try again.');
      });
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="auth-page">
      <button onClick={handleBackClick} className="back-button">
        &larr; back
      </button>
      <div className="auth-content">
        <h3>login</h3>
        <p className="gray-subtitle">welcome back to <span style={{color: "white"}}>debate</span><span style={{color: "#4CAF50"}}>arena</span></p>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="input-container">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
              required
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              required
            />
          </div>
          <button type="submit" className="auth-button">login</button>
        </form>
        <p className="auth-link">
          don't have an account? <Link to="/signup">sign up</Link>
        </p>
        <div className="separator">or</div>
        <button onClick={handleGoogleSignIn} className="google-auth-button">
          <img src={googleLogo} alt="Google Logo" className="google-logo" />
          sign in with Google
        </button>
      </div>
    </div>
  );
};

export default LoginPage; 