import React from 'react';
import styles from '../styles/DebateArena.module.css';

const PvPMessage = ({ msg, isGold }) => {
  const className = `${styles.message} ${styles[`user-${msg.side.toLowerCase()}`]}`;
  const containerClass = `${styles['message-container']} ${styles[msg.side.toLowerCase()]} ${
    isGold ? styles['gold-glow'] : ''
  }`;

  return (
    <div className={containerClass}>
      <div className={className}>
        {msg.content}
      </div>
    </div>
  );
};

export default PvPMessage;