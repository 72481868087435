import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Flag, MessageSquare, Sparkles, Crown, Lock } from "lucide-react";
import annotationPlugin from "chartjs-plugin-annotation";
import styles from "../styles/EvaluationReportCard.module.css";
import {
  calculateCumulativeScores,
  getDisplayScore,
} from "../utils/scoreUtils";
import { generateDebateSummary } from "../utils/api";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import {
  getCurrentUserAndProfile,
  flagDebate,
  sendFeedbackMsg,
  isUserGold,
  canUserUnlock,
  checkDailyEvaluation,
  unlockDebate
} from "../queries/Queries";
import {
  createEvaluationCheckout,
  handleUpgrade
} from '../queries/stripeQueries'
import CountdownTimer from './CountdownTimer';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

// Dummy data for blurred state
const DUMMY_CHART_DATA = {
  labels: ["1", "2", "3", "4", "5"],
  datasets: [{
    label: "score",
    data: [2, 5, -1, 3, -2],
    borderColor: "#808080",
    borderWidth: 2,
    tension: 0.1,
    fill: true,
    backgroundColor: (context) => {
      const chart = context.chart;
      const { ctx, chartArea } = chart;
      if (!chartArea) return null;
      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0.5, "rgba(255, 69, 0, 0.2)");
      gradient.addColorStop(0.5, "rgba(76, 175, 80, 0.2)");
      return gradient;
    },
    pointBackgroundColor: ["#4CAF50", "#4CAF50", "#FF4500", "#4CAF50", "#FF4500"],
    pointBorderColor: ["#4CAF50", "#4CAF50", "#FF4500", "#4CAF50", "#FF4500"],
    pointRadius: 3,
    pointHoverRadius: 4,
    pointBorderWidth: 1,
  }]
};

const DUMMY_SUMMARY = `
# Analysis Report

## Pivotal Moments
- Strong opening argument establishing key premises
- Effective counter to opponent's main points

## Your Strengths
- Clear logical progression in arguments
- Good use of evidence and examples

## Areas for Improvement
- Could strengthen rebuttals
- More specific examples needed
- Consider opposing viewpoints earlier
`;

const EvaluationReportCard = ({
  evaluations,
  topic,
  personaName,
  onClose,
  onHide,
  messages,
  userSide,
  summary,
  setSummary,
  mode,
  id,
  rfd,
  displayScore
}) => {
  const navigate = useNavigate();
  const [viewSetting, setViewSetting] = useState(1);
  const [isLoadingViewState, setIsLoadingViewState] = useState(true);
  const [remainingEvals, setRemainingEvals] = useState(0);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [flagReason, setFlagReason] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [hasDaily, setHasDaily] = useState(false);

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  // Determine view setting on mount
  useEffect(() => {
    const determineViewSetting = async () => {
      try {
        setIsLoadingViewState(true);
        
        // If user has Gold subscription, show full view (3)
        const { isGold, error: goldError } = await isUserGold();
        if (goldError) throw new Error(goldError);
        
        if (isGold) {
          setViewSetting(3);
          return;
        }
        
        // Check daily evaluation
        const dailyResult = await checkDailyEvaluation();
        if (!dailyResult.error && dailyResult.hasDaily) {
          setHasDaily(true);
          setViewSetting(1);
          return;
        }

        // Check purchased evaluations
        const { canUnlock, remaining, error } = await canUserUnlock();
        if (!error && remaining > 0) {
          setRemainingEvals(remaining);
          setViewSetting(1);
          return;
        }

        // If no evaluations available, show purchase view
        setViewSetting(4);
        
      } catch (error) {
        console.error('Error determining view setting:', error);
        setViewSetting(4); // Default to purchase view on error
      } finally {
        setIsLoadingViewState(false);
      }
    };

    determineViewSetting();
  }, []);

  // Generate summary when evaluation report is shown
  useEffect(() => {
    if (!summary && messages.length !== 0 && userAndProfile) {
      generateSummary();
    }
  }, [userAndProfile, messages.length, summary]);

  const handleRevealContent = async () => {
    try {
      const { success, remainingEvaluations, used_daily, error } = await unlockDebate(id);
      if (success) {
        setViewSetting(3); // Only change view after successful unlock
        if (!used_daily) {  // Only update remaining evals if we used a purchased one
          setRemainingEvals(remainingEvaluations || 0);
        }
        setHasDaily(false);  // Reset hasDaily since we used it if it was available
      } else {
        alert(error || 'Failed to use evaluation. Please try again.');
      }
    } catch (error) {
      console.error('Error using evaluation:', error);
      alert('An error occurred while unlocking the content.');
    }
  };

  const generateSummary = async () => {
    const roundByRoundData = evaluations
      .map((e, index) => {
        const cumulativeScores = calculateCumulativeScores(
          evaluations.slice(0, index + 1)
        );
        const roundScore = getDisplayScore(
          cumulativeScores[cumulativeScores.length - 1]
        );
        return `round ${index + 1}: ${e.side} - score: ${roundScore.toFixed(
          3
        )} - ${e.summary.toLowerCase()}`;
      })
      .join("\n");

    const debateTranscript = messages
      .filter((msg) => msg.role !== "system")
      .map((msg) => {
        const speaker = (msg.side || msg.role).toUpperCase();
        return `${speaker}: ${msg.content.toLowerCase()}`;
      })
      .join("\n\n");

    try {
      const generatedSummary = await generateDebateSummary(
        topic.toLowerCase(),
        roundByRoundData,
        debateTranscript,
        userSide.toLowerCase()
      );
      setSummary(generatedSummary.toLowerCase());
    } catch (error) {
      console.error("Failed to generate debate summary:", error);
      setSummary("failed to generate debate summary. please try again later.");
    }
  };

  const handleFeedbackSubmit = async () => {
    if (!feedbackMessage.trim()) {
      alert("Please enter your feedback before submitting.");
      return;
    }
    
    try {
      const success = await sendFeedbackMsg(feedbackMessage);
      if (success) {
        setShowFeedbackModal(false);
        setFeedbackMessage("");
        alert("Thank you for your feedback!");
      } else {
        alert("Failed to send feedback. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("An error occurred while sending feedback. Please try again.");
    }
  };

  const handleFlagSubmit = async () => {
    if (!flagReason.trim()) {
      alert("Please provide a reason for flagging this debate.");
      return;
    }
    
    try {
      const success = await flagDebate(flagReason);
      if (success) {
        setShowFlagModal(false);
        setFlagReason("");
        alert("Thank you for reporting this debate.");
      } else {
        alert("Failed to submit report. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting flag:", error);
      alert("An error occurred while submitting the report. Please try again.");
    }
  };

  const cumulativeScores = calculateCumulativeScores(evaluations);
  const displayScores = cumulativeScores.map(getDisplayScore);
  const maxScore = Math.max(...displayScores.map(Math.abs));
  const axisMax = Math.ceil(maxScore * 1.2);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: -axisMax,
        suggestedMax: axisMax,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          zeroLine: {
            type: "line",
            yMin: 0,
            yMax: 0,
            borderColor: "rgba(255, 255, 255, 0.3)",
            borderWidth: 1,
            borderDash: [5, 5],
          },
          forLabel: {
            type: "label",
            yValue: axisMax,
            yAdjust: 10,
            backgroundColor: "transparent",
            content: ["for"],
            color: "#4CAF50",
          },
          againstLabel: {
            type: "label",
            yValue: -axisMax,
            yAdjust: -10,
            backgroundColor: "transparent",
            content: ["against"],
            color: "#FF4500",
          },
        },
      },
    },
  };

  const getChartData = (shouldUseDummy = false) => {
    if (shouldUseDummy) {
      return DUMMY_CHART_DATA;
    }

    return {
      labels: evaluations.map((_, index) => `${index + 1}`),
      datasets: [{
        label: "score",
        data: displayScores,
        borderColor: "#808080",
        borderWidth: 2,
        tension: 0.1,
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return null;
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0.5, "rgba(255, 69, 0, 0.2)");
          gradient.addColorStop(0.5, "rgba(76, 175, 80, 0.2)");
          return gradient;
        },
        pointBackgroundColor: displayScores.map(score => 
          score > 0 ? "#4CAF50" : score < 0 ? "#FF4500" : "#808080"
        ),
        pointBorderColor: displayScores.map(score => 
          score > 0 ? "#4CAF50" : score < 0 ? "#FF4500" : "#808080"
        ),
        pointRadius: 3,
        pointHoverRadius: 4,
        pointBorderWidth: 1,
      }]
    };
  };

  const renderPaywall = () => {

    console.log("USER IS NOT LOGGED IN")

    if (!userAndProfile) {
      return (
        <div className={styles.signupPrompt}>
          <button
            onClick={() => navigate("/signup")}
            className={styles.startButton}
          >
            sign up to access
          </button>
          <p>
            already have an account?{" "}
            <span
              onClick={() => navigate("/login")}
              className={styles.loginLink}
            >
              login
            </span>
          </p>
        </div>
      );
    }
  
    return (
      <div className={styles.paywallContainer}>
        {hasDaily ? (
          // Show daily evaluation option
          <div className={styles.evaluationsCounter}>
            <div className={styles.counterLabel}>
              You have 1 free daily evaluation available!
            </div>
          </div>
        ) : (
          // Show purchased evaluations counter
          <div className={styles.evaluationsCounter}>
            <div className={styles.counterValue}>{remainingEvals}</div>
            <div className={styles.counterLabel}>
              evaluations remaining today
            </div>
          </div>
        )}
  
        {hasDaily ? (
          <button onClick={handleRevealContent} className={styles.revealButton}>
            <Lock className={styles.revealIcon} />
            Use Daily Evaluation
          </button>
        ) : remainingEvals > 0 ? (
          <button onClick={handleRevealContent} className={styles.revealButton}>
            <Lock className={styles.revealIcon} />
            Use 1 evaluation and reveal
          </button>
        ) : (
          <button
            onClick={() => navigate("/checkout/single")}
            className={styles.purchaseButton}
          >
            <Sparkles className={styles.purchaseIcon} />
            Purchase single evaluation ($1.29)
          </button>
        )}
  
        <div className={styles.goldPromotion}>
          <div className={styles.goldHeader}>
            <Crown className={styles.goldIcon} />
            <h3>Upgrade to Gold</h3>
          </div>
          <p className={styles.goldDescription}>
            Get unlimited evaluations, premium AI personas, and advanced analytics
          </p>
          <ul className={styles.goldFeatures}>
            <li>Unlimited debate evaluations</li>
            <li>Advanced AI feedback</li>
            <li>Premium debate personas</li>
            <li>Priority support</li>
          </ul>
          <button
            onClick={() => navigate("/checkout")}
            className={styles.goldButton}
          >
            Unlock Gold
          </button>
        </div>
      </div>
    );
  };

  const PurchaseView = ({ navigate, id }) => {
    const handleSingleEvaluation = async () => {
      if(!userAndProfile){
        navigate('/login')
      }
      try {
        console.log('Starting checkout with id:', id); // Debug log
        const checkoutUrl = await createEvaluationCheckout(id);
        window.location.href = checkoutUrl;
      } catch (error) {
        console.error('Error starting checkout:', error);
        if (error.message === 'Please login first') {
          alert('Please login to purchase an evaluation');
          navigate('/login');
        } else {
          alert('An error occurred. Please try again.');
        }
      }
    };
  
    const handleGoldUpgrade = async () => {
      try {
        // Using monthly plan by default
        navigate('/checkout')
      } catch (error) {
        console.error('Error starting gold upgrade:', error);
        if (error.message === 'Please login first') {
          alert('Please login to upgrade to Gold');
          navigate('/login');
        } else {
          alert('An error occurred. Please try again.');
        }
      }
    };
  
    return (
      <div className={styles.paywallOverlay}>
        <div className={styles.purchaseOptions}>
          <div className={styles.countdownContainer}>
            <CountdownTimer />
          </div>
          
          {/* Single Evaluation Option */}
          <div className={styles.purchaseCard}>
            <div className={styles.purchaseTitle}>Single Evaluation</div>
            <div className={styles.purchasePrice}>$1.29</div>
            <div className={styles.purchaseDescription}>
              Get instant access to this debate evaluation
            </div>
            <button 
              onClick={handleSingleEvaluation}
              className={`${styles.purchaseButton} ${styles.single}`}
            >
              <Sparkles size={16} />
              Buy Now
            </button>
          </div>
  
          {/* Premium Option */}
          <div className={`${styles.purchaseCard} ${styles.premium}`}>
            <div className={styles.purchaseTitle}>
              <Crown className={styles.goldIcon} /> Upgrade to Gold
            </div>
            <div className={styles.purchaseDescription}>
              Get unlimited evaluations, premium AI personas, and advanced analytics
            </div>
            <button 
              onClick={handleGoldUpgrade}
              className={`${styles.purchaseButton} ${styles.premium}`}
            >
              <Crown size={16} />
              Upgrade to Gold
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const shouldBlur = viewSetting === 1;
    
    switch (viewSetting) {
      case 1: // Blurred view with evaluation counter
        return (
          <>
            <div className={styles.mainContent}>
              <div className={shouldBlur ? `${styles.blurred}` : ""}>
                <div className={styles.rfdContainer}>
                  <h4>reason for decision</h4>
                  <ReactMarkdown>
                    {shouldBlur ? 
                      "**I voted for [redacted]**\n\n[Analysis hidden until unlocked]" : 
                      rfd}
                  </ReactMarkdown>
                </div>
                <div className={styles.chartContainer}>
                  <Line data={getChartData(shouldBlur)} options={chartOptions} />
                </div>
                <div className={styles.summaryContainer}>
                  <h4>analysis report</h4>
                  <ReactMarkdown>
                    {shouldBlur ? DUMMY_SUMMARY : summary}
                  </ReactMarkdown>
                </div>
              </div>
              {shouldBlur && <div className={styles.paywallOverlay}>{renderPaywall()}</div>}
            </div>
          </>
        );
      
      case 3: // Full view
        return (
          <div className={styles.mainContent}>
            <div className={styles.rfdContainer}>
              <h4>reason for decision</h4>
              <ReactMarkdown>{rfd}</ReactMarkdown>
            </div>
            <div className={styles.chartContainer}>
              <Line data={getChartData(false)} options={chartOptions} />
            </div>
            <div className={styles.summaryContainer}>
              <h4>analysis report</h4>
              <ReactMarkdown>{summary}</ReactMarkdown>
            </div>
          </div>
        );
      
      case 4: // Purchase view
        return (
          <>
          <div>
            <div className={styles.mainContent}>
              <div className={styles.blurred}>
                <div className={styles.rfdContainer}>
                  <h4>reason for decision</h4>
                  <ReactMarkdown>
                    {"**I voted for [redacted]**\n\n[Analysis hidden until unlocked]"}
                  </ReactMarkdown>
                </div>
                <div className={styles.chartContainer}>
                  <Line data={getChartData(true)} options={chartOptions} />
                </div>
                <div className={styles.summaryContainer}>
                  <h4>analysis report</h4>
                  <ReactMarkdown>{DUMMY_SUMMARY}</ReactMarkdown>
                </div>
              </div>
              <PurchaseView id={id} navigate={navigate} />
            </div>
          </div>
          </>
        );
        
      default:
        return null;
    }
  };

  // Loading state
  if (isLoadingViewState) {
    return (
      <div className={styles.evaluationReportCard}>
        <div className={styles.loadingState}>
          Loading evaluation...
        </div>
      </div>
    );
  }

  return (
    <div className={styles.evaluationReportCard}>
      <div className={styles.header}>
        <button className={styles.button} onClick={onHide}>
          hide
        </button>
        <h3 className={styles.title}>
          {messages.length === 0 ? (
            <span>seems like nobody showed up!</span>
          ) : (
            <>
              <span className={styles.highlight}>
                {cumulativeScores[cumulativeScores.length - 1] === 0
                  ? ""
                  : "winner:"}
              </span>{" "}
              <span>
                {cumulativeScores[cumulativeScores.length - 1] === 0
                  ? "it was a tie!"
                  : (cumulativeScores[cumulativeScores.length - 1] > 0 &&
                      userSide.toLowerCase() === "for") ||
                    (cumulativeScores[cumulativeScores.length - 1] < 0 &&
                      userSide.toLowerCase() === "against")
                  ? userAndProfile?.profile?.username || "you"
                  : personaName || "ai"}
              </span>
            </>
          )}
        </h3>
        <button className={styles.button} onClick={onClose}>
          new debate
        </button>
      </div>

      {messages.length > 0 && (
        <div className={styles.contentSection}>
          <h4 className={styles.topic}>
            <span className={styles.highlight}>topic:</span>{" "}
            <span>{topic.toLowerCase()}</span>
          </h4>
          <h4 className={styles.topic}>
            <span className={styles.highlight}>score difference:</span>{" "}
            <span>{displayScore}</span>
          </h4>
          {renderContent()}
        </div>
      )}

      <div className={styles.fixedBottom}>
        <div className={styles.actionButtons}>
          <div className={styles.actionItem}>
            <button
              className={`${styles.actionButton} ${styles.feedback}`}
              onClick={() => setShowFeedbackModal(true)}
            >
              <MessageSquare size={14} />
              give us your feedback!
            </button>
          </div>
          <div className={styles.actionItem}>
            <button
              className={`${styles.actionButton} ${styles.flag}`}
              onClick={() => setShowFlagModal(true)}
            >
              <Flag size={14} />
              flag this debate
            </button>
          </div>
        </div>
      </div>

      {showFeedbackModal && (
        <div className={styles.modalOverlay}>
          <div
            className={styles.flagModal}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>share your feedback</h3>
            <textarea
              className={styles.flagTextarea}
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
              placeholder="tell us what you think"
              autoFocus
            />
            <div className={styles.modalButtons}>
              <button
                className={`${styles.modalButton} ${styles.cancelButton}`}
                onClick={() => {
                  setShowFeedbackModal(false);
                  setFeedbackMessage("");
                }}
              >
                cancel
              </button>
              <button
                className={`${styles.modalButton} ${styles.submitButton}`}
                onClick={handleFeedbackSubmit}
                disabled={!feedbackMessage.trim()}
              >
                submit
              </button>
            </div>
          </div>
        </div>
      )}

      {showFlagModal && (
        <div className={styles.modalOverlay}>
          <div
            className={styles.flagModal}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>what's concerning about this debate?</h3>
            <textarea
              className={styles.flagTextarea}
              value={flagReason}
              onChange={(e) => setFlagReason(e.target.value)}
              placeholder="please describe your concern"
              autoFocus
            />
            <div className={styles.modalButtons}>
              <button
                className={`${styles.modalButton} ${styles.cancelButton}`}
                onClick={() => {
                  setShowFlagModal(false);
                  setFlagReason("");
                }}
              >
                cancel
              </button>
              <button
                className={`${styles.modalButton} ${styles.submitButton}`}
                onClick={handleFlagSubmit}
                disabled={!flagReason.trim()}
              >
                submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluationReportCard;