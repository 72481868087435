import React, { useState, useEffect } from "react";
import { Search, Plus, Clock, ChevronDown } from "lucide-react";
import { useNavigate } from "react-router-dom";
import TopMenuBar from "../components/TopMenuBar";
import SetupDebateRoomModal from "../components/SetupDebateRoomModal";
import ReadyModal from "../components/ReadyModal";
import styles from "../styles/RoomsHome.module.css";
import { supabase } from "../queries/Queries";
import {
  fetchActiveRooms,
  subscribeToRoomUpdates,
  joinDebateRoom,
  checkNotifications,
} from "../queries/roomQueries";
import { getCurrentUserAndProfile, handleSignOut } from "../queries/Queries";

const RoomFilterDropdown = ({ selectedFilter, onFilterChange }) => {
  return (
    <div className={styles.dropdownContainer}>
      <select 
        value={selectedFilter}
        onChange={(e) => onFilterChange(e.target.value)}
        className={styles.dropdown}
      >
        <option value="public">Public Rooms</option>
        <option value="in-progress">In Progress</option>
      </select>
      <ChevronDown className={styles.dropdownIcon} />
    </div>
  );
};

const RoomsHome = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [userRooms, setUserRooms] = useState([]);
  const [otherRooms, setOtherRooms] = useState([]);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [hasNotificationPermission, setHasNotificationPermission] = useState(false);
  const [error, setError] = useState(null);
  const itemsPerPage = 8;
  const navigate = useNavigate();
  const [roomFilter, setRoomFilter] = useState("public");

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if ("Notification" in window) {
        const permission = await Notification.requestPermission();
        setHasNotificationPermission(permission === "granted");
      }
    };

    requestNotificationPermission();
  }, []);

  const sendBrowserNotification = (title, body) => {
    if (hasNotificationPermission && document.hidden) {
      new Notification(title, {
        body,
        silent: false,
      });
    }
  };

  const refreshRooms = async () => {
    try {
      const { userRooms, otherRooms, error } = await fetchActiveRooms();
      if (error) throw new Error(error);
      setUserRooms(userRooms);
      setOtherRooms(otherRooms);
      setError(null);
    } catch (err) {
      console.error("Error refreshing rooms:", err);
      setError("Failed to load debate rooms. Please refresh the page.");
    }
  };

  useEffect(() => {
    let roomSubscription;
    let readyPhaseSubscription;

    const initialize = async () => {
      setIsLoading(true);
      try {
        const userResult = await getCurrentUserAndProfile();
        if (!userResult) {
          setError("Failed to load user profile");
          return;
        }
        setUserAndProfile(userResult);

        if (userResult?.user) {
          await refreshRooms();

          roomSubscription = await subscribeToRoomUpdates(userResult.user.id, async () => {
            await refreshRooms();
            await checkForNotifications();
          });

          readyPhaseSubscription = supabase
            .channel(`ready-phase-${userResult.user.id}`)
            .on(
              'postgres_changes',
              {
                event: 'UPDATE',
                schema: 'public',
                table: 'debate_rooms',
                filter: `creator_id=eq.${userResult.user.id} OR opponent_id=eq.${userResult.user.id}`,
              },
              async (payload) => {
                const room = payload.new;
                if (room.status === 'ready_phase') {
                  const isCreator = userResult.user.id === room.creator_id;
                  setNotification({
                    type: isCreator ? 'creator' : 'opponent',
                    room: room
                  });
                  await refreshRooms();
                }
              }
            )
            .subscribe();

          await checkForNotifications();
        }
      } catch (err) {
        console.error("Error initializing RoomsHome:", err);
        setError("Failed to initialize. Please refresh the page.");
      } finally {
        setIsLoading(false);
      }
    };

    initialize();

    return () => {
      if (roomSubscription?.unsubscribe) {
        roomSubscription.unsubscribe();
      }
      if (readyPhaseSubscription?.unsubscribe) {
        readyPhaseSubscription.unsubscribe();
      }
    };
  }, [hasNotificationPermission]);

  const checkForNotifications = async () => {
    try {
      const notif = await checkNotifications();
      if (notif) {
        setNotification(notif);
        if (notif.type === "creator") {
          sendBrowserNotification(
            "Opponent Found!",
            `Someone has joined your debate on: ${notif.room.topic}`
          );
        } else {
          sendBrowserNotification(
            "Ready to Debate!",
            `Your debate room is ready: ${notif.room.topic}`
          );
        }
      }
    } catch (error) {
      console.error("Error checking notifications:", error);
    }
  };

  const handleOpenSetup = () => {
    if (!userAndProfile) {
      navigate("/login");
      return;
    }
    setIsSetupModalOpen(true);
  };

  const handleRoomCreated = (room) => {
    setIsSetupModalOpen(false);
    refreshRooms();
    sendBrowserNotification(
      "Room Created",
      "Your debate room is ready. You will be notified when someone joins."
    );
  };

  const handleJoinRoom = async (room) => {
    if (!userAndProfile) {
      navigate('/login');
      return;
    }
  
    navigate(`/debate/${room.id}`);
    
  };

  const handleSignOutClick = async () => {
    try {
      const success = await handleSignOut();
      if (success) {
        setUserAndProfile(null);
        navigate("/");
      } else {
        throw new Error("Sign out failed");
      }
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to sign out. Please try again.");
    }
  };

  const filteredRooms = search.trim()
    ? otherRooms.filter((room) =>
        room.topic.toLowerCase().includes(search.toLowerCase())
      )
    : otherRooms;

  const totalPages = Math.ceil(filteredRooms.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRooms = filteredRooms.slice(indexOfFirstItem, indexOfLastItem);

  if (isLoading) {
    return (
      <div className={styles.pageContainer}>
        <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOutClick} />
        <div className={styles.loading}>Loading rooms...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.pageContainer}>
        <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOutClick} />
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>{error}</p>
          <button 
            className={styles.retryButton}
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <TopMenuBar
        userAndProfile={userAndProfile}
        onSignOut={handleSignOutClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <h1>Debate in an Online Room</h1>
          <p>Join a public debate room or privately challenge a friend</p>
        </div>

        <div className={styles.actionsContainer}>
          <div className={styles.searchContainer}>
            <Search className={styles.searchIcon} size={20} />
            <input
              type="text"
              placeholder="Search by topic..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={styles.searchInput}
            />
          </div>
          <button className={styles.createButton} onClick={handleOpenSetup}>
            <Plus size={20} />
            Create Room
          </button>
        </div>

        <div className={styles.roomsList}>
          {userRooms.length > 0 && (
            <>
              <div className={styles.sectionHeader}>
                <h2>Your Active Room</h2>
              </div>
              {userRooms.map((room) => (
                <div
                  key={room.id}
                  className={`${styles.roomCard} ${styles.userRoom}`}
                  onClick={() => {
                  }}
                >
                  <div className={styles.mainContent}>
                    <div className={styles.topicAndSide}>
                      <h2 className={styles.roomTopic}>{room.topic}</h2>
                      <div
                        className={`${styles.statusIndicator} ${
                          room.opponent ? styles.matched : styles.waiting
                        }`}
                      >
                        {room.opponent ? (
                          <span className={styles.joinDebate}>
                            Continue to Debate →
                          </span>
                        ) : (
                          <span className={styles.waitingText}>
                            <Clock size={16} />
                            Waiting for Opponent
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={styles.roomDetails}>
                      <span className={styles.sideInfo}>
                        Your Side: <span className={styles.side}>{room.creatorSide}</span>
                      </span>
                      <span className={styles.expires}>
                        Expires in: <span className={styles.time}>{room.expiresIn}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <hr className={styles.divider} />
            </>
          )}

          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderContent}>
              <h2>{roomFilter === "public" ? "Public Rooms" : "In Progress"}</h2>
              <RoomFilterDropdown
                selectedFilter={roomFilter}
                onFilterChange={setRoomFilter}
              />
            </div>
          </div>

          {currentRooms.length > 0 ? (
            currentRooms.map((room) => (
              <div
                key={room.id}
                className={`${styles.roomCard} ${styles.joinableRoom}`}
                onClick={() => handleJoinRoom(room)}
              >
                <div className={styles.mainContent}>
                  <div className={styles.topicAndSide}>
                    <h2 className={styles.roomTopic}>{room.topic}</h2>
                    <div
                      className={`${styles.sideIndicator} ${
                        styles[room.creatorSide.toLowerCase()]
                      }`}
                    >
                      Join as:{" "}
                      {room.creatorSide === "For"
                        ? "Against"
                        : room.creatorSide === "Against"
                        ? "For"
                        : "Random"}
                    </div>
                  </div>
                  <div className={styles.roomDetails}>
                    <span className={styles.createdBy}>
                      Created by: <span className={styles.username}>{room.createdBy}</span>
                    </span>
                    <span className={styles.expires}>
                      Expires in: <span className={styles.time}>{room.expiresIn}</span>
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noRooms}>
              No active debate rooms available. Create one to get started!
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className={styles.pagination}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`${styles.pageButton} ${
                  currentPage === index + 1 ? styles.activePage : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>

      <SetupDebateRoomModal
        isOpen={isSetupModalOpen}
        onClose={() => setIsSetupModalOpen(false)}
        onRoomCreated={handleRoomCreated}
      />

      {notification && (
        <ReadyModal
          isOpen={true}
          room={notification.room}
          isCreator={notification.type === "creator"}
          readyState={{
            creatorReady: notification.room.creator_ready,
            opponentReady: notification.room.opponent_ready
          }}
          onClose={() => setNotification(null)}
          userAndProfile={userAndProfile}
        />
      )}
    </div>
  );
};

export default RoomsHome;