import React from 'react';
import styles from '../styles/AI.module.css';
import { useNavigate } from 'react-router-dom'

const DefaultResult = ({ onClose, debateResultId, isCreator }) => {
  // Helper function to get pronouns based on perspective

  const navigate = useNavigate();

  const getPerspectiveText = (forSelf, forOther) => {
    return isCreator ? forSelf : forOther;
  };

  // Common messages that can be reused
  const cooldownMessage = "Your opponent will receive a temporary cooldown period before being able to join new debates.";
  const exitMessage = "You may now exit this debate and start a new one.";

  // Define all possible result messages
  const resultMessages = {
    // When creator wins
    4: {
      header: getPerspectiveText("You were kicked", "The opponent was kicked"),
      description: getPerspectiveText(
        "You have been removed from the debate. The opponent has been given the default win.",
        `The opponent has been kicked. You have been given the default win. ${cooldownMessage} ${exitMessage}`
      )
    },
    5: {
      header: getPerspectiveText("You were kicked", "The opponent was kicked"),
      description: getPerspectiveText(
        "You have been removed from the debate. The opponent has been given the default win.",
        `The opponent has been kicked. You have been given the default win. ${cooldownMessage} ${exitMessage}`
      )
    },
    6: {
      header: "Ready up timeout.",
      description: "Ready up times are 60 seconds to ensure quicker matchmaking."
    },
    7: {
      header: "The creator of this room has left.",
      description: "Unfortunately the creator has dissolved this room. You have been given the default win. If you'd like to debate this same topic, create a room with the same topic!" 
    },
    8: {
      header: getPerspectiveText("You have forfeited", "The opponent has forfeited"),
      description: getPerspectiveText(
        "You have forfeited the debate. The opponent has been given the default win.",
        "The opponent has forfeited. You have been given the default win."
      )
    },
    9: {
      header: getPerspectiveText("You have been disconnected", "The opponent has been disconnected"),
      description: getPerspectiveText(
        "You have lost connection. The opponent has been given the default win.",
        "The opponent has lost connection. You have been given the default win."
      )
    },
    10: {
      header: getPerspectiveText("You have disconnected", "The opponent has disconnected"),
      description: getPerspectiveText(
        "You have failed to connect. The opponent has been given the default win.",
        "The opponent has failed to connect. You have been given the default win."
      )
    },
    11: {
      header: getPerspectiveText("You have idled", "The opponent has idled"),
      description: getPerspectiveText(
        "You have been idle for too long. The opponent has been given the default win.",
        "The opponent has been idle for too long. You have been given the default win."
      )
    },
    12: {
      header: getPerspectiveText("You have forfeited", "The opponent has forfeited"),
      description: getPerspectiveText(
        "You have forfeited the debate. The opponent has been given the default win.",
        "The opponent has forfeited. You have been given the default win."
      )
    },
    13: {
      header: getPerspectiveText("You have forfeited", "The opponent has forfeited"),
      description: getPerspectiveText(
        "You have forfeited the debate. The opponent has been given the default win.",
        "The opponent has forfeited. You have been given the default win."
      )
    }
  };

  const result = resultMessages[debateResultId] || {
    header: "Debate Ended",
    description: "This debate has ended unexpectedly."
  };

  const onClose67 = () => {
    navigate('/roomshome');
  }

  return (
    <div className={styles.cardOverlay}>
      <div className={styles.cardContent}>
        <button className={styles.closeButton} onClick={(debateResultId === 7 || debateResultId === 6) ? onClose67 : onClose}>×</button>
        <p>{result.header}</p>
        <p>{result.description}</p>
        <p>Note: Multiple default wins may affect matchmaking to ensure fair and engaging debates.</p>
      </div>
    </div>
  );
};

export default DefaultResult;