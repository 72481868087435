import evalCard from "../assets/evalcard.png";
import nietzsche from "../assets/nietzsche.jpeg";

export const PRICING_CONFIG = {
    monthly: {
      free: {
        price: "0",
        period: "mo",
        cta: "Current Plan",
        title: "Free",
      },
      gold: {
        originalPrice: "7.99",
        price: "4.99",
        period: "mo",
        cta: "Upgrade to Gold",
        title: "Gold",
        subtitle: "Exclusive features & priority access",
        badge: "Exclusive Early Access"
      }
    },
    yearly: {
      free: {
        price: "0",
        period: "year",
        cta: "Current Plan",
        title: "Free",
      },
      gold: {
        originalPrice: "95.99",
        price: "29.99",
        period: "year",
        cta: "Upgrade to Gold",
        title: "Gold",
        subtitle: "Exclusive features & priority access",
        badge: "Exclusive Early Access"
      }
    }
};

export const FEATURES = {
  free: [
    { name: "Unlimited debates", included: true },
    { name: "Limited AI personas", included: false },
    { name: "One free evaluation per day", included: false }, 
    { name: "Preliminary debate analysis", included: false },
    { name: "Basic AI evaluator and debater models", included: false }
  ],
  gold: [
    { name: "Unlimited debates", included: true },
    { name: "Immersive, **expert-level** AI personas", included: true },
    { name: "**Unlimited** Evaluations", included: true },
    { name: "**Comprehensive** turn-by-turn debate analysis", included: true },
    { name: "**State-of-the-art** AI evaluator and debater models", included: true }
  ]
};

export const FEATURE_HIGHLIGHTS = [
  {
    title: "In-depth AI-Powered Performance Analysis",
    description: "Receive comprehensive reports that highlight your strengths, pinpoint weaknesses, and guide you to refine your debating skill set.",
    image: evalCard
  },
  {
    title: "Immersive, Expert-Level Personas",
    description: "Challenge yourself against expert AI personas—each designed to push your critical thinking and rhetorical abilities to new heights.",
    image: nietzsche
  }
];

export const FAQ_ITEMS = [
  {
    question: "Can I switch plans anytime?",
    answer: "Yes, you can upgrade or downgrade your plan at any time. Your benefits will adjust accordingly."
  },
  {
    question: "Do you have a mobile app?",
    answer: "Not yet, but we're working on one! Join our Discord to stay updated."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept all major credit cards and are secured through Stripe."
  },
  {
    question: "Can I get a refund?", 
    answer: "Yes, we offer a 48-hour money-back guarantee—no questions asked. Please email support@debatearena.app within 24 hours for a refund."
  }
];