import React, { useState, useEffect } from "react";
import { AlertCircle, CheckCircle } from "lucide-react";
import styles from "../styles/DebateArena.module.css";

const Message = ({ msg, isGold }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const side = msg.role.split('-')[1];
    const hasDroppedArgs = msg.droppedArguments?.count > 0 || msg.droppedArguments?.dropped?.length > 0;
    const dropCount = msg.droppedArguments?.count || 
      (msg.droppedArguments?.dropped?.length || 1);
  
    // Handle mobile touch events
    const handleTooltipToggle = (e) => {
      e.stopPropagation();
      if (window.innerWidth <= 767) {
        setIsTooltipVisible(!isTooltipVisible);
      }
    };
  
    // Close tooltip when clicking outside
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (isTooltipVisible && window.innerWidth <= 767) {
          setIsTooltipVisible(false);
        }
      };
  
      if (isTooltipVisible) {
        document.addEventListener('click', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [isTooltipVisible]);
  
    return (
      <div className={`${styles['message-container']} ${styles[side]} ${
        isGold && msg.role.startsWith('user') ? styles['gold-glow'] : ''
      }`}>
        <div className={`${styles.message} ${styles[msg.role]}`}>
          {msg.content}
        </div>
        
        {msg.droppedArguments && (
          <div 
            className={`${styles.argumentIndicator} ${styles.popIn}`}
            onClick={handleTooltipToggle}
          >
            {!hasDroppedArgs ? (
              <div className={`${styles.perfectResponse} ${styles.bounceIn}`}>
                <CheckCircle size={window.innerWidth <= 767 ? 20 : 16} />
              </div>
            ) : (
              <div className={`${styles.dropCount} ${styles.pulseIn}`}>
                <AlertCircle 
                  size={window.innerWidth <= 767 ? 20 : 16} 
                  className={styles.dropIcon}
                />
                <span className={styles.dropNumber}>{dropCount}</span>
                <div className={`${styles.dropTooltip} ${isTooltipVisible ? styles['tooltip-active'] : ''}`}>
                  <div className={styles.tooltipContent}>
                    <h4>Dropped Arguments:</h4>
                    <ul>
                      {msg.droppedArguments.dropped && 
                       msg.droppedArguments.dropped.length > 0 ? (
                        msg.droppedArguments.dropped.map((arg, i) => (
                          <li key={i}>{arg}</li>
                        ))
                      ) : (
                        <li>• No specific argument provided</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default Message;