export const calculateCumulativeScores = (evaluations) => {
    return evaluations.reduce((acc, evaluation, index) => {
      let scoreChange = evaluation.score;
      if (evaluation.side.toLowerCase() === "against") {
        scoreChange = -scoreChange; // invert score when "against" side
      }
      const newScore = (index === 0 ? 0 : acc[index - 1]) + scoreChange;
      acc.push(Math.min(Math.max(newScore, -100), 100));
      return acc;
    }, []);
  };

  export const calculateDebateResult = (cumulativeScores, userSide) => {
    const finalScore = cumulativeScores[cumulativeScores.length - 1];
    
    if (finalScore === 0) return 3; // Tie
    
    // User is on the "for" side
    if (userSide.toLowerCase() === "for") {
      return finalScore > 0 ? 1 : 2; // 1 for win, 2 for loss
    }
    
    // User is on the "against" side
    return finalScore < 0 ? 1 : 2; // Reversed logic for "against" side
  };
  
export const getDisplayScore = (score) => score / 10;