import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  Menu, 
  Bot, 
  Users,
  Settings,
  History,
  LogOut,
  Heart,
  LogIn,
  Landmark,
  Swords
} from 'lucide-react';
import { isUserGold } from '../queries/Queries';
import styles from '../styles/TopMenuBar.module.css';
import coliseumLogo from '../images/coliseum_logo.png';

const GoldUsername = ({ username, isGold }) => {
  if (!isGold) return <span>{username}</span>;
  
  return (
    <div className={styles.goldUsernameContainer}>
      <span className={styles.goldUsername}>{username}</span>
    </div>
  );
};

const TopMenuBar = ({ userAndProfile, onSignOut }) => {
  const navigate = useNavigate();
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const [goldStatus, setGoldStatus] = useState(false);
  const navDrawerRef = useRef(null);

  useEffect(() => {
    const checkGoldStatus = async () => {
      if (userAndProfile?.user) {
        const { isGold } = await isUserGold(userAndProfile.user.id);
        setGoldStatus(isGold);
      } else {
        setGoldStatus(false);
      }
    };
    
    checkGoldStatus();
  }, [userAndProfile]);

  const handleDiscordClick = () => {
    window.open('https://discord.gg/f5uN7rkRw3', '_blank');
  };

  const handleDonateClick = () => {
    window.open('https://donate.stripe.com/8wMeVNaex80db2o3cc', '_blank');
  };

  const toggleNavDrawer = () => {
    setIsNavDrawerOpen(!isNavDrawerOpen);
  };

  const handleLogout = () => {
    navigate('/');
    setIsNavDrawerOpen(false);
    onSignOut();
  };

  const handleSocialClick = () => {
    if(userAndProfile?.user){
      navigate('/profile?tab=friends');
      setIsNavDrawerOpen(false);
    }
    else{
      navigate('/signup');
    }
  };

  const handlePvPClick = () => {
    if(userAndProfile?.user) {
      navigate('/challenge');
      setIsNavDrawerOpen(false);
    } else {
      navigate('/signup');
    }
  };

  const handleAIClick = () => {
    if(userAndProfile?.user) {
      navigate('/ai');
      setIsNavDrawerOpen(false);
    } else {
      navigate('/signup');
    }
  };

  const handleBPClick = () => {
    if(userAndProfile?.user) {
      navigate('/bpai');
      setIsNavDrawerOpen(false);
    } else {
      navigate('/signup');
    }
  };

  return (
    <>
      <div className={styles.topMenuBar}>
        <div className={styles.leftSection}>
          <div 
            className={`${styles.hamburgerButton} ${isNavDrawerOpen ? styles.active : ''}`} 
            onClick={toggleNavDrawer}
          >
            <Menu size={24} />
          </div>
        </div>
        <Link to="/" className={styles.logoLink}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>debate<span style={{color: goldStatus ? "#FFD700" : "#4CAF50"}}>arena</span></div>
            <img src={coliseumLogo} alt="Coliseum Logo" className={styles.coliseumLogo} />
          </div>
        </Link>
        <div className={styles.rightSection}>
          <div className={`${styles.discordLink} ${styles.desktopOnly}`} onClick={handleDiscordClick}>
            <svg className={styles.discordIcon} width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/>
            </svg>
            Join our Discord
          </div>
        </div>
      </div>

      {/* Navigation Drawer */}
      {isNavDrawerOpen && (
        <div className={styles.navDrawer} ref={navDrawerRef}>
          <div className={styles.navContent}>
            <div className={styles.navItems}>
              <div className={styles.menuItemContainer}>
                <div 
                  className={`${styles.menuItem} ${!userAndProfile?.user ? styles.socialDisabled : ''}`} 
                  onClick={handlePvPClick}
                >
                  <Swords size={20} />
                  <span>Debate Online</span>
                </div>
                {!userAndProfile?.user && (
                  <div className={styles.signUpPrompt}>
                    <span>Sign Up</span>
                  </div>
                )}
              </div>
              
              <div className={styles.menuItemContainer}>
                <div 
                  className={`${styles.menuItem} ${!userAndProfile?.user ? styles.socialDisabled : ''}`} 
                  onClick={handleAIClick}
                >
                  <Bot size={20} />
                  <span>Play vs AI</span>
                </div>
                {!userAndProfile?.user && (
                  <div className={styles.signUpPrompt}>
                    <span>Sign Up</span>
                  </div>
                )}
              </div>

              <div className={styles.menuItemContainer}>
                <div 
                  className={`${styles.menuItem} ${!userAndProfile?.user ? styles.socialDisabled : ''}`} 
                  onClick={handleBPClick}
                >
                  <Landmark size={20} />
                  <span>British Parliamentary</span>
                </div>
                {!userAndProfile?.user && (
                  <div className={styles.signUpPrompt}>
                    <span>Sign Up</span>
                  </div>
                )}
              </div>

              <div className={styles.menuItemContainer}>
                <div 
                  className={`${styles.menuItem} ${!userAndProfile?.user ? styles.socialDisabled : ''}`} 
                  onClick={handleSocialClick}
                >
                  <Users size={20} />
                  <span>Social</span>
                </div>
                {!userAndProfile?.user && (
                  <div className={styles.signUpPrompt}>
                    <span>Sign Up</span>
                  </div>
                )}
              </div>

              <div className={`${styles.menuItem} ${styles.mobileOnly}`} onClick={handleDiscordClick}>
                <svg className={styles.discordIcon} width="20" height="20" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/>
                </svg>
                <span>Join our Discord</span>
              </div>

              <div className={styles.donateButton} onClick={handleDonateClick}>
                <Heart size={14} />
                <span>Support Us</span>
              </div>
            </div>

            {/* Profile Card Section - Conditional Render */}
            {userAndProfile?.user ? (
              <div className={styles.profileCard}>
                <div onClick={() => navigate('/profile')} className={styles.profileInfo}>
                  <div className={styles.profileImageWrapper}>
                    {goldStatus && (
                      <span className={styles.profileCrownIcon}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.crownSvg}>
                          <path 
                            d="M3 17h18v2H3v-2z" 
                            fill="#FFD700"
                            stroke="#FFD700" 
                            strokeWidth="1"
                          />
                          <path 
                            d="M3 17l2.5-7.5L9 14l3-7 3 7 3.5-4.5L21 17H3z" 
                            fill="#FFD700"
                            stroke="#FFD700" 
                            strokeWidth="1"
                          />
                          <circle cx="5.5" cy="11" r="1" fill="#FF0000"/>
                          <circle cx="12" cy="8" r="1" fill="#4CAF50"/>
                          <circle cx="18.5" cy="11" r="1" fill="#2196F3"/>
                        </svg>
                      </span>
                    )}
                    <div className={styles.profileImageContainer}>
                      <img 
                        src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                        alt="Profile" 
                        className={styles.profileImage} 
                      />
                    </div>
                  </div>
                  <div className={styles.profileName}>
                    <GoldUsername 
                      username={userAndProfile.profile?.username || userAndProfile.user.email}
                      isGold={goldStatus}
                    />
                  </div>
                </div>
                
                <div className={styles.profileActions}>
                  <button 
                    className={styles.profileButton} 
                    onClick={() => navigate('/profile')}
                  >
                    <History size={16} />
                    <span>Past Debates</span>
                  </button>
                  <button 
                    className={styles.profileButton} 
                    onClick={() => navigate('/settings')}
                  >
                    <Settings size={16} />
                    <span>Settings</span>
                  </button>
                  <button 
                    className={styles.logoutButton} 
                    onClick={handleLogout}
                  >
                    <LogOut size={16} />
                    <span>Log out</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.loginCard}>
                <button 
                  className={styles.signupButton} 
                  onClick={() => navigate('/login')}
                >
                  <LogIn size={16} />
                  <span>Log in to get started</span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TopMenuBar;