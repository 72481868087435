import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../queries/Queries";
import TopMenuBar from "../components/TopMenuBar";
import RoomShareModal from "../components/RoomShareModal";
import DebateRoomArena from "../components/DebateRoomArena";
import styles from "../styles/DebateRoom.module.css";
import DefaultResult from '../components/DefaultResult'
import { getCurrentUserAndProfile } from "../queries/Queries";
import {
  joinDebateRoomWithLink,
  updateRoomToInProgress,
  deleteDebateRoom,
  updateHeartbeat,
  leaveDebate
} from "../queries/roomQueries";
import { 
  isUserGold
} from '../queries/Queries';

const DebateRoom = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const arenaRef = useRef(null);
  const chatBoxRef = useRef(null);
  const channelRef = useRef(null);
  const heartbeatIntervalRef = useRef(null);

  // State Management
  const [room, setRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [error, setError] = useState(null);
  const [debateStarted, setDebateStarted] = useState(false);
  const [showRoomShareModal, setShowRoomShareModal] = useState(true);
  const [isForSidePlayerGold, setIsForSidePlayerGold] = useState(false);
  const [isAgainstSidePlayerGold, setIsAgainstSidePlayerGold] = useState(false);
  const [opponentOnline, setOpponentOnline] = useState(false);
  const [showCreatorLeftScreen, setShowCreatorLeftScreen] = useState(false)

  // Helper function for sides
  const getUserSideInfo = (room, userId) => {
    if (!room || !userId) return {};

    const isCreatorUser = userId === room.creator_id;
    const userSide = isCreatorUser ? room.creator_side : (room.creator_side === "For" ? "Against" : "For");
    
    const forSidePlayer = {
      username: room.creator_side === "For" ? room.creator?.username : room.opponent?.username,
      isUser: userSide === "For",
      ready: room.creator_side === "For" ? room.creator_ready : room.opponent_ready,
      id: room.creator_side === "For" ? room.creator_id : room.opponent_id,
      isGold: isForSidePlayerGold
    };

    const againstSidePlayer = {
      username: room.creator_side === "Against" ? room.creator?.username : room.opponent?.username,
      isUser: userSide === "Against",
      ready: room.creator_side === "Against" ? room.creator_ready : room.opponent_ready,
      id: room.creator_side === "Against" ? room.creator_id : room.opponent_id,
      isGold: isAgainstSidePlayerGold
    };

    return {
      userSide,
      opponentSide: userSide === "For" ? "Against" : "For",
      forSidePlayer,
      againstSidePlayer,
    };
  };

  // Handle both players ready
  const handleBothPlayersReady = async (currentUserId, creatorId) => {
    const isCurrentUserCreator = currentUserId === creatorId;
    
    console.log(`Current user (${currentUserId}) is${isCurrentUserCreator ? '' : ' not'} the creator (${creatorId})`);
    
    if (!isCurrentUserCreator) {
      console.log("Not the creator, skipping debate start");
      return;
    }
  
    const turnDeadline = new Date();
    turnDeadline.setSeconds(turnDeadline.getSeconds() + 60);
  
    try {
      const { data, error } = await supabase.rpc("start_debate", {
        room_id: roomId,
        turn_deadline: turnDeadline.toISOString(),
      });
  
      if (error) {
        console.error("Error starting debate:", error);
      } else {
        console.log("Debate started successfully");
      }
    } catch (error) {
      console.error("Exception in handleBothPlayersReady:", error);
    }
  };

  // Handle gold status check
  useEffect(() => {
    const checkGoldStatus = async () => {
      if (!room || !userAndProfile?.user?.id) return;
  
      try {
        const forSidePlayerId = room.creator_side === "For" 
          ? room.creator_id 
          : room.opponent_id;
  
        const againstSidePlayerId = room.creator_side === "Against" 
          ? room.creator_id 
          : room.opponent_id;
  
        const forGoldStatus = await isUserGold(
          forSidePlayerId === userAndProfile.user.id ? null : forSidePlayerId
        );
        setIsForSidePlayerGold(forGoldStatus.isGold);
  
        const againstGoldStatus = await isUserGold(
          againstSidePlayerId === userAndProfile.user.id ? null : againstSidePlayerId
        );
        setIsAgainstSidePlayerGold(againstGoldStatus.isGold);
      } catch (error) {
        console.error("Error checking gold status:", error);
      }
    };
  
    checkGoldStatus();
  }, [room?.id, userAndProfile?.user?.id]);

  // Handle heartbeat system

  const waitingPhaseArr = ["ready_phase", "in_progress"]

  useEffect(() => {
    if (!room || !userAndProfile?.user?.id) return;
    const opponentId = isCreator ? room.opponent_id : room.creator_id;
  
    if(waitingPhaseArr.includes(room?.status)) {
      // Send own heartbeat and check opponent's every 5 seconds
      const sendHeartbeatAndCheck = async () => { 
        await updateHeartbeat(roomId, "waiting");
        
        // Actively check opponent's last heartbeat
        const { data } = await supabase
          .from('debate_room_heartbeats')
          .select('last_heartbeat')
          .eq('room_id', roomId)
          .eq('user_id', opponentId)
          .single();
          
        if (data) {
          const lastHeartbeat = new Date(data.last_heartbeat);
          const now = new Date();
          const isActive = (now - lastHeartbeat) < 10000;
          console.log("isActive is " + isActive);
          setOpponentOnline(isActive);
        }
      }
  
      sendHeartbeatAndCheck();
      const interval = setInterval(sendHeartbeatAndCheck, 5000);
  
      return () => clearInterval(interval);
    }
  }, [room?.id, userAndProfile?.user?.id, isCreator, room?.status]);

  useEffect(() => {
    if(opponentOnline == false && isCreator && room !== null && room?.opponent !== null){
      console.log("Enter if")
      leaveDebate(room?.id, room?.opponent_id)
      setDebateStarted(false);
      setShowRoomShareModal(true);
    }
    else{
      if(opponentOnline == false && !isCreator && room != null && room?.creator !== null){
        console.log("Enter else")
        leaveDebate(room?.id, room?.creator_id)
        setShowCreatorLeftScreen(true);
      }
    }}, [opponentOnline]);

  useEffect(() => {
    if(room?.status === "in_progress"){

    }
  }, [room?.messages, room?.status])

  // Initialize room and setup subscriptions
  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      try {
        const userResult = await getCurrentUserAndProfile();
        if (!userResult) {
          navigate("/login");
          return;
        }
        setUserAndProfile(userResult);

        const { data: roomData, error: roomError } = await supabase
          .from("debate_rooms")
          .select(
            `
            *,
            creator:creator_id(username),
            opponent:opponent_id(username)
          `
          )
          .eq("id", roomId)
          .single();

        if (roomError) throw roomError;
        if (!roomData) throw new Error("Room not found");

        if (userResult.user.id !== roomData.creator_id) {
          const { data: roomLinkData, error: roomLinkError } =
            await joinDebateRoomWithLink(roomId);
          
          

          if (roomLinkError) throw roomLinkError;
          if (!roomLinkData) throw new Error("Room not found");

          if (
            roomLinkData.creator_id !== userResult.user.id &&
            roomLinkData.opponent_id !== userResult.user.id
          ) {
            throw new Error("You are not authorized to view this debate");
          }
          setRoom(roomLinkData);
        } else {
          if (
            roomData.creator_id !== userResult.user.id &&
            roomData.opponent_id !== userResult.user.id
          ) {
            throw new Error("You are not authorized to view this debate");
          }
          setRoom(roomData);
          
        }

        setOpponentOnline(true)
        console.log("The current user ID is " + userResult.user.id + ". And the creator ID is " + roomData.creator_id)

        setIsCreator(userResult.user.id === roomData.creator_id);

        console.log("So isCreator is " + isCreator)

        if (roomData.status === "in_progress") {
          setDebateStarted(true);
          setShowRoomShareModal(false);
        }

        // Set up real-time subscription
        channelRef.current = supabase
          .channel(`room:${roomId}`)
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "debate_rooms",
              filter: `id=eq.${roomId}`,
            },
            async (payload) => {
              console.log("Received room update:", payload);

              const { data: { session } } = await supabase.auth.getSession();
              if (!session) {
                console.log("No active session, skipping update");
                return;
              }

              const { data } = await supabase
                .from("debate_rooms")
                .select(
                  `
                  *,
                  creator:creator_id(username),
                  opponent:opponent_id(username)
                `
                )
                .eq("id", roomId)
                .single();

              if (data) {
                console.log("Updated room data:", data);
                setRoom(data);

                if (data.status === "ready_phase") {
                  console.log("Room in ready phase");
                  console.log("Creator ready:", data.creator_ready);
                  console.log("Opponent ready:", data.opponent_ready);
                  console.log("Current user ID:", session.user.id);
                  console.log("Creator ID:", data.creator_id);

                  if (data.creator_ready && data.opponent_ready) {
                    console.log("Both players ready, checking if creator...");
                    await handleBothPlayersReady(
                      session.user.id,
                      data.creator_id
                    );
                  }
                }

                if (data.status === "in_progress") {
                  console.log("Debate is now in progress");
                  setDebateStarted(true);
                  setShowRoomShareModal(false);
                }
              }
            }
          )
          .subscribe();
      } catch (error) {
        console.error("Error initializing debate room:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();

    return () => {
      if (channelRef.current) {
        console.log("Unsubscribing from room updates");
        channelRef.current.unsubscribe();
      }
    };
  }, [roomId, navigate]);

  const roomShareModalClose = () => {
    setShowRoomShareModal(false);
    deleteDebateRoom(roomId)
    navigate('/rooms')
  }

  const roomShareModalJoined = () => {
    setShowRoomShareModal(false);
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <TopMenuBar userAndProfile={userAndProfile} />
        <div className={styles.loading}>Loading debate room...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <TopMenuBar userAndProfile={userAndProfile} />
        <div className={styles.error}>
          <h2>Error</h2>
          <p>{error}</p>
          <button
            className={styles.backButton}
            onClick={() => navigate("/rooms")}
          >
            Back to Rooms
          </button>
        </div>
      </div>
    );
  }

  const playerInfo = getUserSideInfo(room, userAndProfile?.user?.id);

  return (
    <div className={styles.container}>
      <TopMenuBar userAndProfile={userAndProfile} />

      {!debateStarted && room && showRoomShareModal && (
        <RoomShareModal
          isOpen={true}
          room={room}
          onJoin={roomShareModalJoined}
          onClose={roomShareModalClose}
          isCreator={isCreator}
          {...playerInfo}
        />
      )}

      {showCreatorLeftScreen && 
        <DefaultResult
          onClose={() => setShowCreatorLeftScreen(false)}
          debateResultId={room.debate_result_id}
          isCreator={isCreator}
        />
      }

      {room && room.opponent && (
        <DebateRoomArena
          arenaRef={arenaRef}
          chatBoxRef={chatBoxRef}
          room={room}
          userAndProfile={userAndProfile}
          isCreator={isCreator}
          onDebateStart={() => setDebateStarted(true)}
          opponentOnline={opponentOnline}
          {...playerInfo}
        />
      )}
    </div>
  );
};

export default DebateRoom;