import React, { useRef, useState, useEffect } from 'react';
import { Search, MoreVertical, UserPlus, UserMinus, X, Shield, Check } from 'lucide-react';
import styles from '../styles/FriendsTab.module.css';
import { 
  searchUser, 
  getFriends, 
  getFriendRequests, 
  addFriend, 
  removeFriend, 
  acceptFriendRequest, 
  blockUser,
  cancelFriendRequest,
  checkFriendRequestStatus 
} from '../queries/Queries';

const TabButton = ({ active, onClick, children, count }) => (
  <button
    onClick={onClick}
    className={`${styles['tab-button']} ${active ? styles['active'] : ''}`}
  >
    {children}
    {count > 0 && (
      <span className={styles['count-badge']}>
        {count}
      </span>
    )}
  </button>
);

const UserActions = ({ user, type, onUpdate }) => {
  const actionsRef = useRef(null);
  const [showUnsendPrompt, setShowUnsendPrompt] = useState(false);
  const [showBlockMenu, setShowBlockMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRequestPending, setHasRequestPending] = useState(false);

  useEffect(() => {
    const checkRequestStatus = async () => {
      if (type === 'discovery') {
        const result = await checkFriendRequestStatus(user.id);
        setHasRequestPending(result.exists);
      }
    };
    checkRequestStatus();
  
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setShowBlockMenu(false);
        setShowUnsendPrompt(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [user.id, type]);

  const handleAddFriend = async () => {
    setIsLoading(true);
    try {
      const result = await addFriend(user.id);
      if (result.success) {
        setHasRequestPending(true);
        if (onUpdate) onUpdate();
      }
    } catch (error) {
      console.error('Error adding friend:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelRequest = async () => {
    setIsLoading(true);
    try {
      const result = await cancelFriendRequest(user.id);
      if (result.success) {
        setHasRequestPending(false);
        setShowUnsendPrompt(false);
        if (onUpdate) onUpdate();
      }
    } catch (error) {
      console.error('Error canceling request:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAcceptRequest = async () => {
    setIsLoading(true);
    try {
      await acceptFriendRequest(user.id);
      onUpdate();
    } catch (error) {
      console.error('Error accepting request:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFriend = async () => {
    setIsLoading(true);
    try {
      await removeFriend(user.id);
      onUpdate();
    } catch (error) {
      console.error('Error removing friend:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlock = async () => {
    setIsLoading(true);
    try {
      await blockUser(user.id);
      setShowBlockMenu(false);
      onUpdate();
    } catch (error) {
      console.error('Error blocking user:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles['actions-container']} ref={actionsRef}>
      {showUnsendPrompt && (
        <div className={styles['unsend-prompt']}>
          <button
            onClick={handleCancelRequest}
            className={styles['unsend-button']}
            disabled={isLoading}
          >
            Unsend friend request?
          </button>
        </div>
      )}

      {showBlockMenu && (
        <div className={styles['block-menu']}>
          <button
            onClick={handleBlock}
            className={styles['block-button']}
            disabled={isLoading}
          >
            Block User
          </button>
        </div>
      )}

      <div className={styles['action-buttons']}>
        {type === 'request' && (
          <>
            <button 
              onClick={handleAcceptRequest}
              className={`${styles['action-button']} ${styles['accept']}`}
              disabled={isLoading}
            >
              <UserPlus size={18} />
            </button>
            <button 
              onClick={handleBlock}
              className={`${styles['action-button']} ${styles['reject']}`}
              disabled={isLoading}
            >
              <X size={18} />
            </button>
          </>
        )}
        {type === 'friend' && (
          <button 
            onClick={handleRemoveFriend}
            className={`${styles['action-button']} ${styles['remove']}`}
            disabled={isLoading}
          >
            <UserMinus size={18} />
          </button>
        )}
        {type === 'discovery' && (
          <button 
            onClick={hasRequestPending ? () => setShowUnsendPrompt(true) : handleAddFriend}
            className={`${styles['action-button']} ${hasRequestPending ? styles['pending'] : styles['add']}`}
            disabled={isLoading}
          >
            {hasRequestPending ? <Check size={18} /> : <UserPlus size={18} />}
          </button>
        )}
        <button 
          onClick={() => setShowBlockMenu(!showBlockMenu)}
          className={`${styles['action-button']} ${styles['more']}`}
        >
          <MoreVertical size={18} />
        </button>
      </div>
    </div>
  );
};

const formatLastSeen = (timestamp) => {
  const now = new Date();
  const lastSeen = new Date(timestamp);
  const diffInMinutes = Math.floor((now - lastSeen) / (1000 * 60));
  
  if (diffInMinutes < 1) return 'just now';
  if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours}h ago`;
  
  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays}d ago`;
};

const UserCard = ({ user, type, onUpdate }) => {
  const formatLastSeen = (date) => {
    if (!date) return 'Never';
    const lastSeen = new Date(date);
    const now = new Date();
    const diffTime = Math.abs(now - lastSeen);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    
    if (diffMinutes < 1) return 'Just now';
    if (diffMinutes < 60) return `${diffMinutes}m ago`;
    if (diffHours < 24) return `${diffHours}h ago`;
    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    return lastSeen.toLocaleDateString();
  };

  const isOnline = (new Date() - new Date(user.updated_at)) < 1000 * 60 * 5;

  return (
    <div className={styles['user-card']}>
      <div className={styles['user-info']}>
        <div className={styles['avatar']}>
          <img
            src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
            alt={user.username}
          />
        </div>
        
        <div className={styles['user-details']}>
          <div className={styles['username-container']}>
            <span className={styles['username']}>{user.username}</span>
            <img
              src="https://flagcdn.com/w20/us.png"
              alt="US"
              className={styles['flag']}
            />
          </div>
          
          <div className={styles['additional-info']}>
            
            {user.bio && (
              <div className={styles['bio']}>{user.bio}</div>
            )}
            
            <div className={styles['last-seen']}>
              <div className={`${styles['status-dot']} ${isOnline ? styles['online'] : styles['offline']}`} />
              <span>
                {isOnline 
                  ? 'Online' 
                  : `Last seen ${formatLastSeen(user.updated_at)}`
                }
              </span>
            </div>
          </div>
        </div>
      </div>

      <UserActions 
        user={user} 
        type={type} 
        onUpdate={onUpdate}
      />
    </div>
  );
};

const FriendsTab = () => {
  const [activeSection, setActiveSection] = useState('friends');
  const [localSearchInput, setLocalSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadFriendsData();
  }, []);

  const loadFriendsData = async () => {
    setIsLoading(true);
    try {
      const [friendsResult, requestsResult] = await Promise.all([
        getFriends(),
        getFriendRequests()
      ]);

      if (friendsResult.data) {
        setFriends(friendsResult.data);
      }
      if (requestsResult.data) {
        setFriendRequests(requestsResult.data);
      }
    } catch (error) {
      console.error('Error loading friends data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!localSearchInput.trim()) return;
    
    setIsSearching(true);
    try {
      const results = await searchUser(localSearchInput);
      setSearchResults(results || []);
      if (activeSection !== 'discover') {
        setActiveSection('discover');
      }
    } catch (error) {
      console.error('Error searching users:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <div className={styles['friends-tab']}>
      {/* Search Section */}
      <div className={styles['search-container']}>
        <div className={styles['search-input-container']}>
          <Search className={styles['search-icon']} size={20} />
          <input
            type="text"
            placeholder="Search users..."
            value={localSearchInput}
            onChange={(e) => setLocalSearchInput(e.target.value)}
            onKeyPress={handleKeyPress}
            className={styles['search-input']}
          />
        </div>
        <button
          onClick={handleSearch}
          className={styles['search-button']}
          disabled={isSearching}
        >
          {isSearching ? 'Searching...' : 'Search'}
        </button>
      </div>

      {/* Tabs Navigation */}
      <div className={styles['tabs']}>
        <TabButton 
          active={activeSection === 'friends'} 
          onClick={() => setActiveSection('friends')}
          count={friends.length}
        >
          Friends
        </TabButton>
        <TabButton 
          active={activeSection === 'requests'} 
          onClick={() => setActiveSection('requests')}
          count={friendRequests.length}
        >
          Requests
        </TabButton>
        <TabButton 
          active={activeSection === 'discover'} 
          onClick={() => setActiveSection('discover')}
        >
          Discover
        </TabButton>
      </div>

      {/* Main Content Area */}
      <div className={styles['user-list']}>
        {isLoading ? (
          <div className={styles['loading-message']}>
            Loading...
          </div>
        ) : (
          <>
            {/* Friends List */}
            {activeSection === 'friends' && (
              <>
                {friends.length > 0 ? (
                  friends.map(friend => (
                    <UserCard 
                      key={friend.id} 
                      user={friend} 
                      type="friend" 
                      onUpdate={loadFriendsData}
                    />
                  ))
                ) : (
                  <div className={styles['empty-message']}>
                    No friends yet. Start by discovering new users!
                  </div>
                )}
              </>
            )}

            {/* Friend Requests List */}
            {activeSection === 'requests' && (
              <>
                {friendRequests.length > 0 ? (
                  friendRequests.map(request => (
                    <UserCard 
                      key={request.profiles.id} 
                      user={request.profiles} 
                      type="request" 
                      onUpdate={loadFriendsData}
                    />
                  ))
                ) : (
                  <div className={styles['empty-message']}>
                    No pending friend requests
                  </div>
                )}
              </>
            )}

            {/* Discover Section */}
            {activeSection === 'discover' && (
              <>
                {isSearching ? (
                  <div className={styles['loading-message']}>
                    Searching...
                  </div>
                ) : (
                  <>
                    {searchResults.length > 0 ? (
                      searchResults.map(user => (
                        <UserCard 
                          key={user.id} 
                          user={user} 
                          type="discovery" 
                          onUpdate={loadFriendsData}
                        />
                      ))
                    ) : (
                      <div className={styles['empty-message']}>
                        {localSearchInput 
                          ? 'No users found matching your search' 
                          : 'Search for users to discover new friends'}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FriendsTab;