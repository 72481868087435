import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { X, Loader, ChevronDown } from "lucide-react";
import { createDebateRoom } from "../queries/roomQueries";
import { generateDebateTopic } from "../utils/api";
import styles from "../styles/SetupDebateRoom.module.css";
import modalStyles from "../styles/SetupDebateRoomModal.module.css";

const SetupDebateRoomModal = ({ isOpen, onClose, onRoomCreated }) => {

  const navigate = useNavigate()

  const [topic, setTopic] = useState("");
  const [side, setSide] = useState("Random");
  const [visibility, setVisibility] = useState("public");
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  const textareaRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        150
      )}px`;
    }
  }, [topic]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleAutoSelectTopic = async () => {
    try {
      setError(null);
      const newTopic = await generateDebateTopic();
      const formattedTopic = newTopic.replace(/["]+/g, "").toLowerCase();
      setTopic(formattedTopic.charAt(0).toUpperCase() + formattedTopic.slice(1));
    } catch (error) {
      console.error("Error generating topic:", error);
      setTopic("Universal basic income");
      setError("Failed to generate topic. Using default.");
    }
  };

  const getRandomSide = () => (Math.random() < 0.5 ? "For" : "Against");

  const handleCreateRoom = async () => {
    if (!topic.trim()) {
      setError("Please enter a topic to start the debate.");
      return;
    }

    setIsCreating(true);
    setError(null);

    try {
      const finalSide = side === "Random" ? getRandomSide() : side;
      const { data, error: roomError } = await createDebateRoom(
        topic,
        finalSide,
        visibility === "public"
      );

      if (roomError) {
        setError(roomError);
        return;
      }
      if (data) {
        onRoomCreated(data);
        const url = `/debate/${data.id}`;
        const newTab = window.open(url, '_blank');
        if (newTab) newTab.opener = null;
      } else {
        setError("Failed to create room. Please try again.");
      }
    } catch (error) {
      console.error("Error creating room:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className={modalStyles.modalOverlay}>
      <div ref={modalRef} className={modalStyles.modalContainer}>
        <div className={modalStyles.modalHeader}>
          <h2 className={styles.setupHeading}>Create Debate Room</h2>
          <button 
            onClick={onClose}
            className={modalStyles.closeButton}
          >
            <X size={24} />
          </button>
        </div>

        <div className={styles.panelContent}>
          {error && <div className={styles.errorMessage}>{error}</div>}

          <div className={styles.section}>
            <h3>Topic</h3>
            <div className={styles.topicInputContainer}>
              <textarea
                ref={textareaRef}
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="enter topic (a binary question / stance)"
                className={styles.textarea}
                rows={1}
              />
              <span
                onClick={handleAutoSelectTopic}
                className={styles.autoSelect}
              >
                auto
              </span>
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.section}>
            <h3>Visibility</h3>
            <div className={modalStyles.visibilityContainer}>
              <select
                value={visibility}
                onChange={(e) => setVisibility(e.target.value)}
                className={modalStyles.visibilityDropdown}
              >
                <option value="public">Public (Anyone can join)</option>
                <option value="private">Private (Only people with link)</option>
              </select>
              <ChevronDown className={modalStyles.dropdownIcon} size={16} />
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.section}>
            <h3>Choose Your Side</h3>
            <div className={styles.sideButtons}>
              <button
                className={`${styles.sideButton} ${styles.forButton} ${
                  side === "For" ? styles.active : ""
                }`}
                onClick={() => setSide("For")}
              >
                for
              </button>
              <button
                className={`${styles.sideButton} ${styles.randomButton} ${
                  side === "Random" ? styles.active : ""
                }`}
                onClick={() => setSide("Random")}
              >
                random
              </button>
              <button
                className={`${styles.sideButton} ${styles.againstButton} ${
                  side === "Against" ? styles.active : ""
                }`}
                onClick={() => setSide("Against")}
              >
                against
              </button>
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.formatInfo}>
            <p>Standard Format: 5 rounds of back-and-forth debate</p>
            <p>Each participant will have 3 minutes per round to present their arguments</p>
          </div>

          <div className={styles.startButtonContainer}>
            <button
              className={styles.startButton}
              onClick={handleCreateRoom}
              disabled={!topic.trim() || isCreating}
            >
              {isCreating ? (
                <div className={styles.loadingContainer}>
                  <Loader className={styles.spinner} size={16} />
                  <span>creating room...</span>
                </div>
              ) : (
                "create room"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupDebateRoomModal;