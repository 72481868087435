import React, { useState, useEffect } from "react";
import { Check, CircleDashed, Sparkles, Crown } from "lucide-react";
import TopMenuBar from "../components/TopMenuBar";
import { handleUpgrade } from "../queries/stripeQueries";
import { getCurrentUserAndProfile } from '../queries/Queries'
import {
  PRICING_CONFIG,
  FEATURES,
  FEATURE_HIGHLIGHTS,
  FAQ_ITEMS,
} from "../constants/pricing";
import styles from "../styles/PricingPage.module.css";
import logo from "../assets/coliseum_logo.png";

const PricingPage = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const currentPricing = isYearly
    ? PRICING_CONFIG.yearly
    : PRICING_CONFIG.monthly;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleUpgradeClick = async () => {
    try {
      setIsLoading(true);
      await handleUpgrade(isYearly);
    } catch (error) {
      console.error('Error:', error);
      alert('Please log in to upgrade your subscription');
    } finally {
      setIsLoading(false);
    }
  };

  const parseTextWithBold = (text) => {
    const parts = text.split(/\*\*(.*?)\*\*/g);
    return parts.map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
  };

  return (
    <div className={styles.pricingPage}>
      <TopMenuBar userAndProfile={userAndProfile} />
      <main className={styles.mainContent}>
        <h1 className={styles.heroText}>
          Elevate Your Debates with{" "}
          <img src={logo} className={styles.logoIcon} alt="Logo" />{" "}
          <span className={styles.highlight}>Gold</span>
        </h1>

        <div className={styles.toggleContainer}>
          <div className={styles.toggleGroup}>
            <button
              className={`${styles.toggleButton} ${
                !isYearly ? styles.active : ""
              }`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              className={`${styles.toggleButton} ${
                isYearly ? styles.active : ""
              }`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
              {!isYearly && <span className={styles.discount}>Save 50%</span>}
            </button>
          </div>
        </div>

        <div
          className={`${styles.pricingCards} ${
            isVisible ? styles.visible : ""
          }`}
        >
          {/* Free Plan */}
          <div className={`${styles.pricingCard} free`}>
            <div className={styles.cardHeader}>
              <div className={styles.planName}>
                <h3>{currentPricing.free.title}</h3>
              </div>
            </div>
            <ul className={styles.featureList}>
              {FEATURES.free.map((feature, index) => (
                <li key={index} className={styles.featureItem}>
                  {feature.included ? (
                    <Check
                      className={`${styles.featureIcon} ${styles.checkIconFree}`}
                    />
                  ) : (
                    <CircleDashed
                      className={`${styles.featureIcon} ${styles.xIcon}`}
                    />
                  )}
                  <span
                    className={
                      feature.included
                        ? styles.featureIncluded
                        : styles.featureExcluded
                    }
                  >
                    {feature.name}
                  </span>
                </li>
              ))}
            </ul>
            <div className={styles.currentLabel}>{currentPricing.free.cta}</div>
          </div>

          {/* Gold Plan */}
          <div className={`${styles.pricingCard} ${styles.gold}`}>
            <div className={styles.particles}>
              {[...Array(8)].map((_, i) => (
                <div key={i} className={styles.particle} />
              ))}
            </div>
            <div className={styles.cardBadge}>Limited Time - Early Access</div>
            <div className={styles.earlyAccessBadge}>First 250 Members</div>
            <div className={styles.cardHeader}>
              <div className={styles.planName}>
                <h3 className={styles.highlight}>Gold</h3>
              </div>
              <div className={styles.priceInfo}>
                <div className={styles.pricingComparison}>
                  <div className={styles.discountedPrice}>
                    <div className={styles.currentPrice}>
                      ${currentPricing.gold.price}
                      <span className={styles.period}>
                        /{currentPricing.gold.period}
                      </span>
                    </div>
                    <div className={styles.savingsTag}>
                      Save $
                      {currentPricing.gold.originalPrice -
                        currentPricing.gold.price}
                    </div>
                  </div>
                  <div className={styles.savingsArrow}>←</div>
                  <div className={styles.regularPrice}>
                    <span className={styles.originalPrice}>
                      ${currentPricing.gold.originalPrice}
                    </span>
                    <span className={styles.period}>
                      /{currentPricing.gold.period}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ul className={styles.featureList}>
              {FEATURES.gold.map((feature, index) => (
                <li key={index} className={styles.featureItem}>
                  <Check
                    className={`${styles.featureIcon} ${styles.goldIcon}`}
                  />
                  <span className={styles.featureIncluded}>
                    {parseTextWithBold(feature.name)}
                  </span>
                </li>
              ))}
            </ul>
            <button
              className={styles.upgradeButton}
              onClick={handleUpgradeClick}
              disabled={isLoading}
            >
              <span className={styles.buttonContent}>
                {isLoading ? "Loading..." : currentPricing.gold.cta}{" "}
                <Crown className={styles.buttonIcon} />
              </span>
            </button>
          </div>
        </div>

        {/* Feature Highlights */}
        <div className={styles.featuresHighlight}>
          {FEATURE_HIGHLIGHTS.map((highlight, index) => (
            <div key={index} className={styles.highlightItem}>
              <h3 className={styles.highlightTitle}>
                {index === 0 ? (
                  <Sparkles className={styles.goldIcon} />
                ) : (
                  <Crown className={styles.goldIcon} />
                )}
                {highlight.title}
              </h3>
              <p className={styles.highlightDescription}>{highlight.description}</p>
              <img
                src={highlight.image}
                alt={highlight.title}
                className={styles.highlightImage}
              />
            </div>
          ))}
        </div>

        {/* FAQ Section */}
        <div className={styles.faqSection}>
          <h2 className={styles.faqTitle}>Frequently Asked Questions</h2>
          <div className={styles.faqGrid}>
            {FAQ_ITEMS.map((item, index) => (
              <div key={index} className={styles.faqItem}>
                <h4>{item.question}</h4>
                <p>{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default PricingPage;