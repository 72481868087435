import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import styles from "../styles/StoredDebates.module.css";
import TopMenuBar from "../components/TopMenuBar.js";
import EvaluationReportCard from "../components/StoredDebateEvaluationReportCard";
import { calculateCumulativeScores, getDisplayScore } from "../utils/scoreUtils";
import { getDebateById, getCurrentUserAndProfile, handleSignOut } from "../queries/Queries";

function StoredDebate() {
  const navigate = useNavigate();
  const { debateId } = useParams();
  
  // Main debate data states
  const [debateData, setDebateData] = useState(null);
  const [examSide, setExamSide] = useState(null);
  const [side, setSide] = useState(null);
  const [topic, setTopic] = useState("");
  const [mode, setMode] = useState("");
  const [divArg, setDivArg] = useState(null);
  const [messages, setMessages] = useState([]);
  const [allSources, setAllSources] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [evaluationSummary, setEvaluationSummary] = useState("");
  const [rfd, setRfd] = useState("");
  const [displayScore, setDisplayScore] = useState(null)

  // UI states
  const [showAdditionalSources, setShowAdditionalSources] = useState(false);
  const [cumulativeScore, setCumulativeScore] = useState(0);
  const [currentSummary, setCurrentSummary] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showEvaluationReport, setShowEvaluationReport] = useState(true);
  const [error, setError] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Refs
  const evaluationSummaryRef = useRef(null);
  const evaluationTextRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch user profile and debate data in parallel
        const [userProfileResult, debateInfo] = await Promise.all([
          getCurrentUserAndProfile(),
          getDebateById(debateId)
        ]);

        setUserAndProfile(userProfileResult);

        if (debateInfo) {
          setDebateData(debateInfo);
          // Initialize all states from debate data
          setExamSide(debateInfo.role);
          setSide(debateInfo.position);
          setTopic(debateInfo.topic);
          setMode(debateInfo.mode);
          setDivArg(JSON.parse(debateInfo.examArg));
          setMessages(JSON.parse(debateInfo.messages));
          setAllSources(JSON.parse(debateInfo.sources));
          setEvaluations(JSON.parse(debateInfo.evaluations));
          setSelectedPersona(debateInfo.personaName);
          setEvaluationSummary(debateInfo.summary);
          setRfd(debateInfo.rfd)
          setDisplayScore(debateInfo.finalScore)

          // Calculate initial scores
          const scores = calculateCumulativeScores(JSON.parse(debateInfo.evaluations));
          setCumulativeScore(scores[scores.length - 1]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load debate. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (debateId) {
      fetchData();
    }
  }, [debateId]);

  const handleSignOutClick = async () => {
    try {
      await handleSignOut();
      setUserAndProfile(null);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    }
  };

  const toggleAdditionalSources = () => {
    setShowAdditionalSources(!showAdditionalSources);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Loading state
  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}>Loading debate...</div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>{error}</div>
        <button onClick={() => navigate('/')} className={styles.returnButton}>
          Return to Home
        </button>
      </div>
    );
  }

  // If no debate data is found
  if (!debateData) {
    return (
      <div className={styles.notFoundContainer}>
        <div className={styles.notFoundMessage}>Debate not found</div>
        <button onClick={() => navigate('/')} className={styles.returnButton}>
          Return to Home
        </button>
      </div>
    );
  }

  const amplifiedPercentage = (Math.sign(cumulativeScore) * 
    Math.pow(Math.abs(cumulativeScore) / 100, 0.7) + 1) * 50;
  const isForWinning = cumulativeScore >= 0;

  return (
    <div className="App">
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOutClick} />
      
      {showEvaluationReport && (
        <EvaluationReportCard
          fromStoredDebate={true}
          evaluations={evaluations}
          topic={topic}
          personaName={selectedPersona}
          onClose={() => {
            setShowEvaluationReport(false);
          }}
          onHide={() => setShowEvaluationReport(false)}
          messages={messages}
          userSide={side}
          summary={evaluationSummary}
          setSummary={setEvaluationSummary}
          id={debateId}
          rfd={rfd}
          displayScore={displayScore}
        />
      )}

      <div className={styles["debate-arena"]}>
        {mode === "Cross-Examine" && (
          <>
            <div className={`${styles["cross-exam-sidebar"]} ${sidebarOpen ? styles["sidebar-open"] : ""}`}>
              <p className={`${styles["side-labels"]} ${styles[examSide.toLowerCase()]}`}>
                {examSide === "Examinee" ? "Defend the argument given below" : "Question the argument given below"}
              </p>
              <h3>{examSide === "Examinee" ? "Your" : "Opponent's"} Claim</h3>
              <p>{divArg?.claim}</p>
              <h3>Warrant</h3>
              <p>{divArg?.warrant}</p>
              <h3>Impact</h3>
              <p>{divArg?.impact}</p>
            </div>
            <button className={styles["sidebar-toggle"]} onClick={toggleSidebar}>
              {sidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
            </button>
          </>
        )}

        <div className={styles["cross-exam-arena"]}>
          <div className={styles["evaluation-bar-container"]}>
            <div className={styles["evaluation-bar"]}>
              <div
                className={styles["evaluation-bar-fill"]}
                style={{ width: `${amplifiedPercentage}%` }}
              >
                {isForWinning && (
                  <span className={styles["score-display-for"]}>
                    {displayScore}
                  </span>
                )}
              </div>
              {!isForWinning && (
                <span className={styles["score-display-against"]}>
                  {displayScore}
                </span>
              )}
            </div>
          </div>

          <div className={styles["dynamic-boxes"]}>
            <div className={styles["evaluation-summary"]} ref={evaluationSummaryRef}>
              <div className={styles["evaluation-summary-text"]} ref={evaluationTextRef}>
              </div>
            </div>

            <div className={styles["arena-labels"]}>
              <span className={`${styles["side-labels"]} ${styles.for} ${side === "For" ? styles.active : ""}`}>
                For
              </span>
              <span className={`${styles["side-labels"]} ${styles.against} ${side === "Against" ? styles.active : ""}`}>
                Against
              </span>
            </div>

            <div className={styles["chat-box"]}>
              <div className={styles["topic-message"]}>{topic}</div>
              {messages
                .filter((msg) => msg.role !== "system")
                .map((msg, index) => (
                  <div key={index} className={`${styles.message} ${styles[msg.role]}`}>
                    {msg.content}
                  </div>
                ))}
            </div>
          </div>

          {mode === "Standard" && allSources.length > 0 && (
            <div className={styles["all-sources-container"]}>
              <h3>Sources:</h3>
              <div className={styles["sources-grid"]}>
                {allSources.slice(0, 5).map((roundSources, index) => (
                  <div key={index} className={styles["round-sources"]}>
                    <h4>Round {roundSources.round}</h4>
                    <ul>
                      {roundSources.sources.map((source, sourceIndex) => (
                        <li key={sourceIndex} className={styles["source-item"]}>
                          <a
                            href={source.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={source.title}
                          >
                            {source.title.length > 30
                              ? source.title.substring(0, 30) + "..."
                              : source.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              
              {allSources.length > 5 && (
                <div className={styles["additional-sources"]}>
                  <button
                    className={styles["additional-sources-button"]}
                    onClick={toggleAdditionalSources}
                  >
                    {showAdditionalSources ? "Hide" : "Show"} Additional Sources
                  </button>
                  {showAdditionalSources && (
                    <div className={styles["sources-grid"]}>
                      {allSources.slice(5).map((roundSources, index) => (
                        <div key={index + 5} className={styles["round-sources"]}>
                          <h4>Round {roundSources.round}</h4>
                          <ul>
                            {roundSources.sources.map((source, sourceIndex) => (
                              <li key={sourceIndex} className={styles["source-item"]}>
                                <a
                                  href={source.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={source.title}
                                >
                                  {source.title.length > 30
                                    ? source.title.substring(0, 30) + "..."
                                    : source.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div 
          className={styles.showEvaluation} 
          onClick={() => setShowEvaluationReport(true)}
        >
          Show Evaluation
        </div>
      </div>
    </div>
  );
}

export default StoredDebate;