import React, { useState, useEffect } from 'react';
import { 
  getProfileData, 
  updateUsername, 
  getCurrentUserAndProfile, 
  updateBio, 
  getUsernameExists,
  deleteAccount,
  isUserGold 
} from '../queries/Queries';
import { createPortalSession } from '../queries/stripeQueries';
import styles from '../styles/SettingsPage.module.css';
import TopMenuBar from '../components/TopMenuBar';
import DeleteAccountSection from '../components/DeleteAccountSection';
import { MoreVertical } from 'lucide-react';

const SettingsPage = () => {
  const [profileData, setProfileData] = useState(null);
  const [newUsername, setNewUsername] = useState('');
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [error, setError] = useState('');
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [bio, setBio] = useState('');
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [newBio, setNewBio] = useState('');
  const [usernameErrors, setUsernameErrors] = useState([]);
  const [showSubscriptionMenu, setShowSubscriptionMenu] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const [isGold, setIsGold] = useState(false);

  useEffect(() => {
    fetchProfileData();
    fetchUserAndProfile();
    checkGoldStatus();
  }, []);

  const checkGoldStatus = async () => {
    const result = await isUserGold();
    setIsGold(result?.isGold || false);
  };

  const fetchProfileData = async () => {
    const data = await getProfileData();
    if (data) {
      setProfileData(data);
      setNewUsername(data.username || data.email);
      setBio(data.bio || 'Add a bio');
    }
  };

  const fetchUserAndProfile = async () => {
    const result = await getCurrentUserAndProfile();
    setUserAndProfile(result);
  };

  const validateUsername = async (username) => {
    const errors = [];
    
    if (username.length > 30) {
      errors.push('Username must not exceed 30 characters');
    }

    const validCharactersRegex = /^[a-zA-Z0-9._]*$/;
    if (!validCharactersRegex.test(username)) {
      errors.push('Username can only contain letters, numbers, periods, and underscores');
    }

    const symbolsRegex = /[^a-zA-Z0-9._]/;
    if (symbolsRegex.test(username)) {
      errors.push('Username cannot contain symbols or punctuation marks');
    }

    const emojiRegex = /[\u{1F000}-\u{1FFFF}]/u;
    if (emojiRegex.test(username)) {
      errors.push('Username cannot contain emojis');
    }

    if (errors.length === 0 && username !== profileData?.username) {
      const exists = await getUsernameExists(username);
      if (exists) {
        errors.push('Username is already taken');
      }
    }

    setUsernameErrors(errors);
    return errors.length === 0;
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setNewUsername(value);
    validateUsername(value);
  };

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (newUsername === profileData.username) {
      setIsEditingUsername(false);
      return;
    }

    const isValid = await validateUsername(newUsername);
    if (!isValid) {
      return;
    }

    const success = await updateUsername(newUsername);
    if (success) {
      await fetchProfileData();
      await fetchUserAndProfile();
      setIsEditingUsername(false);
      setUsernameErrors([]);
    } else {
      setError('Failed to update username. Please try again.');
    }
  };

  const handleBioClick = () => {
    setIsEditingBio(true);
    setNewBio(bio);
  };

  const handleBioChange = (e) => {
    setNewBio(e.target.value);
  };

  const handleBioSubmit = async () => {
    setError('');
    const success = await updateBio(newBio);
    if (success) {
      setBio(newBio);
      setIsEditingBio(false);
      await fetchProfileData();
    } else {
      setError('Failed to update bio. Please try again.');
    }
  };

  const handleBioCancel = () => {
    setIsEditingBio(false);
  };

  const handleSignOut = () => {
    setUserAndProfile(null);
  };

  const handleDeleteAccount = async () => {
    const result = await deleteAccount();
    if (result.success) {
      window.location.href = '/';
    } else {
      setError(result.error || 'Failed to delete account');
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setIsSubscriptionLoading(true);
      setError('');
      
      const portalUrl = await createPortalSession();
      window.location.href = portalUrl;
      
    } catch (error) {
      console.error('Error accessing subscription management:', error);
      setError('Failed to access subscription management. Please try again.');
    } finally {
      setIsSubscriptionLoading(false);
      setShowSubscriptionMenu(false);
    }
  };
  
  if (!profileData) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.pageContainer}>
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      <div className={styles.profileContainer}>
        <div className={styles.profileBox}>
          <div className={styles.profileHeader}>
            <div className={styles.profilePicture}>
              <img 
                src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                alt="Profile"
                className={styles.profileImage}
              />
            </div>
            <div className={styles.profileNameStatus}>
              <h1 className={styles.profileName}>{profileData.username || profileData.email}</h1>
              <div className={styles.bioContainer} onClick={handleBioClick}>
                {isEditingBio ? (
                  <>
                    <input
                      type="text"
                      value={newBio}
                      onChange={handleBioChange}
                      className={styles.bioInput}
                    />
                    <div className={styles.bioButtons}>
                      <button onClick={handleBioSubmit} className={`${styles.bioButton} ${styles.saveBioButton}`}>
                        Save
                      </button>
                      <button onClick={handleBioCancel} className={`${styles.bioButton} ${styles.cancelBioButton}`}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <p className={styles.bioText}>{bio}</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.profileInfo}>
            <div className={styles.field}>
              <label>Subscription Plan</label>
              <div className={styles.planContainer}>
                <p className={styles.planInfo}>
                  {isGold ? 'Gold Plan' : 'Basic Plan'}
                  {isGold && profileData?.interval && (
                    <span className={styles.planType}>
                      ({profileData.interval === 'month' ? 'Monthly' : 'Yearly'})
                    </span>
                  )}
                </p>
                {isGold && (
                  <div className={styles.menuContainer}>
                    <button
                      className={styles.menuButton}
                      onClick={() => setShowSubscriptionMenu(!showSubscriptionMenu)}
                      aria-label="Menu"
                    >
                      <MoreVertical size={20} />
                    </button>
                    {showSubscriptionMenu && (
                      <div className={styles.menuDropdown}>
                        <button
                          className={styles.menuItem}
                          onClick={handleCancelSubscription}
                          disabled={isSubscriptionLoading}
                        >
                          Manage Subscription
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {profileData?.status === 'past_due' && (
                <div className={styles.paymentAlert}>
                  Your last payment failed. Please update your payment method to continue your subscription.
                </div>
              )}
              {!isGold && (
                <button 
                  onClick={() => window.location.href = '/checkout'} 
                  className={styles.upgradeButton}
                >
                  Upgrade to Gold
                </button>
              )}
            </div>
            <div className={styles.field}>
              <label>Email</label>
              <p>{profileData.email}</p>
            </div>
            <div className={styles.field}>
              <label>Username</label>
              {isEditingUsername ? (
                <form onSubmit={handleUsernameSubmit} className={styles.editForm}>
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      value={newUsername}
                      onChange={handleUsernameChange}
                      className={styles.usernameInput}
                    />
                    {usernameErrors.length > 0 && (
                      usernameErrors.map((error, index) => (
                        <p key={index} className={styles.error}>{error}</p>
                      ))
                    )}
                    <div className={styles.buttonContainer}>
                      <button type="submit" className={styles.saveButton}>Save</button>
                      <button 
                        onClick={() => {
                          setIsEditingUsername(false);
                          setUsernameErrors([]);
                          setNewUsername(profileData.username || '');
                        }} 
                        className={styles.cancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <p>
                  {profileData.username || 'Not set'}
                  <button 
                    onClick={() => setIsEditingUsername(true)} 
                    className={styles.editButton}
                  >
                    {profileData.username ? 'Edit' : 'Enter username'}
                  </button>
                </p>
              )}
            </div>
            <div className={styles.field}>
              <label>Debates Participated</label>
              <p>{profileData.debateCount}</p>
            </div>
          </div>
          {error && <p className={styles.error}>{error}</p>}
        </div>
        <DeleteAccountSection 
          username={profileData.username || profileData.email}
          onDeleteAccount={handleDeleteAccount}
        />
        <div className={styles.refundNotice}>
          If you had just subscribed to gold less than 48 hours ago and would like to request a refund, email support@debatearena.app.
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;