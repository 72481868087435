import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import styles from "../styles/EvaluationReportCard.module.css";
import {
  calculateCumulativeScores,
  getDisplayScore,
} from "../utils/scoreUtils";
import { generateDebateSummary, generateDebateRoomSummary } from "../utils/api";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { getCurrentUserAndProfile } from "../queries/Queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const DebateRoomEvaluationReportCard = ({
  isDbRoom,
  evaluations,
  topic,
  onHide,
  messages,
  summary,
  forSidePlayer,
  againstSidePlayer,
  winner,
  userSide,
}) => {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const navigate = useNavigate();

  // room.eval can be imported, setShowEval should be directly a part of it, summary and setSummary should be in DebateArena copied from AI.js

  const handleNewDebate = () => {
    // if(userAndProfile){
    if (isDbRoom) {
      navigate("/rooms");
    } else {
      navigate("/ai");
    }
    window.location.reload();
    // }
    // else{
    //   console.log("here and fromStoredDebate: " + fromStoredDebate)
    //   fromStoredDebate ? navigate('/signup') : navigate('/ai')
    // }
  };

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const cumulativeScores = calculateCumulativeScores(evaluations);
  const displayScores = cumulativeScores.map(getDisplayScore);

  const maxScore = Math.max(...displayScores.map(Math.abs));
  const axisMax = Math.ceil(maxScore * 1.2);

  const chartData = {
    labels: evaluations.map((_, index) => `${index + 1}`),
    datasets: [
      {
        label: "score",
        data: displayScores,
        borderColor: "#808080",
        borderWidth: 2,
        tension: 0.1,
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return null;

          // Create gradient
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0.5, "rgba(255, 69, 0, 0.2)"); // Red for negative
          gradient.addColorStop(0.5, "rgba(76, 175, 80, 0.2)"); // Green for positive
          return gradient;
        },
        pointBackgroundColor: displayScores.map((score) =>
          score > 0 ? "#4CAF50" : score < 0 ? "#FF4500" : "#808080"
        ),
        pointBorderColor: displayScores.map((score) =>
          score > 0 ? "#4CAF50" : score < 0 ? "#FF4500" : "#808080"
        ),
        pointRadius: 3,
        pointHoverRadius: 4,
        pointBorderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: -axisMax,
        suggestedMax: axisMax,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          zeroLine: {
            type: "line",
            yMin: 0,
            yMax: 0,
            borderColor: "rgba(255, 255, 255, 0.3)",
            borderWidth: 1,
            borderDash: [5, 5],
          },
          forLabel: {
            type: "label",
            yValue: axisMax,
            yAdjust: 10,
            backgroundColor: "transparent",
            content: [forSidePlayer],
            color: "#4CAF50",
          },
          againstLabel: {
            type: "label",
            yValue: -axisMax,
            yAdjust: -10,
            backgroundColor: "transparent",
            content: [againstSidePlayer],
            color: "#FF4500",
          },
        },
      },
    },
  };

  return (
    <div className={styles.evaluationReportCardRooms}>
      <div className={styles.header}>
        <button className={styles.button} onClick={onHide}>
          hide
        </button>
        <h3 className={styles.title}>
          {messages.length === 0 ? (
            <span>seems like nobody showed up!</span>
          ) : (
            <>
              <span className={styles.highlight}>
                {winner === "Tie" ? "" : "winner:"}
              </span>{" "}
              <span>{winner === "Tie" ? "it was a tie!" : winner}</span>
            </>
          )}
        </h3>
        <button className={styles.button} onClick={handleNewDebate}>
          new debate
        </button>
      </div>

      {messages.length > 0 && (
        <>
          <h4 className={styles.topic}>
            <span className={styles.highlight}>topic:</span>{" "}
            <span>{topic.toLowerCase()}</span>
          </h4>
          <div className={styles.chartContainer}>
            <Line data={chartData} options={chartOptions} />
          </div>
          <div className={styles.summaryContainer}>
            <h4>analysis report</h4>

            <ReactMarkdown
              components={{
                p: ({ node, ...props }) => (
                  <p className={styles.paragraph} {...props} />
                ),
                ul: ({ node, ...props }) => (
                  <ul className={styles.list} {...props} />
                ),
                li: ({ node, ...props }) => (
                  <li className={styles.listItem} {...props} />
                ),
              }}
            >
              {summary}
            </ReactMarkdown>
          </div>
        </>
      )}
    </div>
  );
};

export default DebateRoomEvaluationReportCard;