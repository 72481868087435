import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserAndProfile, isUserGold } from "../queries/Queries";
import BPAi from '../pages/BPAi';

const BPAiWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const userAndProfile = await getCurrentUserAndProfile();
        
        if (!userAndProfile?.user) {
          navigate('/signup');
          return;
        }

        const goldStatus = await isUserGold(userAndProfile.user.id);
        if (!goldStatus.isGold) {
          navigate('/checkout');
          return;
        }
      } catch (error) {
        console.error('Error checking access:', error);
        navigate('/signup');
      }
    };

    checkAccess();
  }, [navigate]);

  return <BPAi />;
};

export default BPAiWrapper;