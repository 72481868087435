import React, { useState } from 'react';
import { Check, Loader, Swords } from 'lucide-react';
import styles from '../styles/ReadyModal.module.css';
import { updateReadyState } from '../queries/roomQueries';

const ReadyModal = ({
  isOpen,
  room,
  isCreator,
  onDebateStart,
  userAndProfile
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleReadyClick = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await updateReadyState(room.id, isCreator);
      if (error) {
        setError(error);
        return;
      }
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
      console.error('Ready state error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen || !room) {
    console.log('Room not found');
    return null;
  }

  const bothReady = room.creator_ready && room.opponent_ready;
  const userReady = isCreator ? room.creator_ready : room.opponent_ready;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        {bothReady ? (
          <div className={styles.success}>
            <Check size={64} className={styles.successIcon} />
            <h2 className={styles.title}>Both Debaters Ready!</h2>
            <p>Entering the arena...</p>
          </div>
        ) : (
          <>
            <div className={styles.modalHeader}>
              <h2 className={styles.title}>Ready Check</h2>
              <div className={styles.topic}>{room.topic}</div>
            </div>

            {error && (
              <div className={styles.error}>{error}</div>
            )}

            <div className={styles.vsContainer}>
              <div className={`${styles.debater} ${styles.debaterFor}`}>
                <div className={styles.debaterHeader}>
                  <span className={`${styles.side} ${styles.sideFor}`}>For</span>
                  {room.creator_ready && <Check size={20} className={styles.ready} />}
                </div>
                <div className={styles.username}>
                  {room.creator?.username || 'Unknown'}
                  {isCreator && ' (You)'}
                </div>
                <div className={`${styles.readyStatus} ${room.creator_ready ? styles.ready : styles.notReady}`}>
                  {room.creator_ready ? 'Ready' : 'Not Ready'}
                </div>
              </div>

              <div className={styles.vsText}>
                <Swords size={24} />
              </div>

              <div className={`${styles.debater} ${styles.debaterAgainst}`}>
                <div className={styles.debaterHeader}>
                  <span className={`${styles.side} ${styles.sideAgainst}`}>Against</span>
                  {room.opponent_ready && <Check size={20} className={styles.ready} />}
                </div>
                <div className={styles.username}>
                  {room.opponent?.username || 'Unknown'}
                  {!isCreator && ' (You)'}
                </div>
                <div className={`${styles.readyStatus} ${room.opponent_ready ? styles.ready : styles.notReady}`}>
                  {room.opponent_ready ? 'Ready' : 'Not Ready'}
                </div>
              </div>
            </div>

            <button 
              className={`${styles.readyButton} ${userReady ? styles.ready : ''}`}
              onClick={handleReadyClick}
              disabled={userReady || isLoading}
            >
              {isLoading ? (
                <Loader className={styles.spinner} size={24} />
              ) : userReady ? (
                'Ready!'
              ) : (
                'Ready Up'
              )}
            </button>

            <div className={styles.timer}>
              Ready check expires in: {getReadyTimeRemaining(room.ready_deadline)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const getReadyTimeRemaining = (deadline) => {
  if (!deadline) return '2:00';
  const now = new Date();
  const deadlineDate = new Date(deadline);
  const diffSeconds = Math.max(0, Math.floor((deadlineDate - now) / 1000));
  const minutes = Math.floor(diffSeconds / 60);
  const seconds = diffSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export default ReadyModal;