import './index.css';
import './styles/App.css';
import * as React from 'react'
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom'
import { App as CapApp } from '@capacitor/app';
import AI from './pages/AI.js';
import Login from './pages/LoginPage.js';
import Signup from './pages/SignUpPage.js'
import LandingPage from './pages/LandingPage.js'
import SupportPage from './pages/SupportPage.js'
import CustomRedirect from './pages/CustomRedirect.js'
import UserStats from './pages/UserStats.js'
import SettingsPage from './pages/SettingsPage.js'
import ProfilePage from './pages/ProfilePage.js'
import PrivacyPolicy from './pages/PrivacyPolicy.js'
import TermsOfUse from './pages/TermsOfUse.js'
import AuthCallback from './pages/AuthCallback.js'
import FactCheckPage from './pages/FactCheckPage.js'
import SelfAI from './pages/self_debate/SelfAI.js'
import StoredDebate from './pages/StoredDebate.js'
import BPAiWrapper from './components/BPAiWrapper.js'
import RoomsHome from './pages/RoomsHome.js';
import DebateRoom from './pages/DebateRoom.js';
import SetupDebateRoom from './pages/SetupDebateRoom.js'
import styles from './styles/UsernameModal.module.css'
import SmartBanner from './components/SmartBanner'
import { getProfileData, getUsernameExists, updateUsername } from "./queries/Queries.js";
import { useState, useEffect } from "react";
import { AlertTriangle } from 'lucide-react';
import PricingPage from './pages/PricingPage.js';
import DefaultResult from './components/DefaultResult.js';

// Deep Link Handler Component
const DeepLinkHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data) => {
      console.log('App opened with URL:', data);
      
      const slug = data.url.split('debate/').pop();
      
      if (slug) {
        console.log('Navigating to debate:', slug);
        navigate(`/debate/${slug}`);
      }
    });

    return () => {
      // Cleanup listener
      CapApp.removeAllListeners();
    };
  }, [navigate]);

  return null;
};

const UsernameValidationModal = ({ oldUsername }) => {
  const [newUsername, setNewUsername] = useState('');
  const [error, setError] = useState('');
  const [isChecking, setIsChecking] = useState(false);

  const validateUsername = async (username) => {
    if (!username) {
      return "Username is required";
    }

    const validationRules = [
      {
        test: (value) => value.length <= 30,
        message: "Username must be 30 characters or less"
      },
      {
        test: (value) => /^[a-zA-Z0-9._]*$/.test(value),
        message: "Username can only contain letters, numbers, periods, and underscores"
      },
      {
        test: (value) => !/[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~`]/.test(value),
        message: "Username cannot contain symbols or punctuation marks"
      },
      {
        test: (value) => !/[\u{1F300}-\u{1F9FF}]/u.test(value),
        message: "Username cannot contain emojis"
      }
    ];

    for (const rule of validationRules) {
      if (!rule.test(username)) {
        return rule.message;
      }
    }

    // Check if username is taken
    const exists = await getUsernameExists(username);
    if (exists) {
      return "This username is already taken";
    }

    return null;
  };

  const handleSubmit = async () => {
    setIsChecking(true);
    setError('');

    try {
      const validationError = await validateUsername(newUsername);
      
      if (validationError) {
        setError(validationError);
        return;
      }

      const success = await updateUsername(newUsername);
      if (success) {
        window.location.href = '/settings';
      } else {
        setError('Failed to update username. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          {oldUsername ? (
            <>
              <AlertTriangle color="#eab308" size={24} />
              <h2 className={styles.modalTitle}>Quick Username Update Needed</h2>
            </>
          ) : (
            <h2 className={styles.modalTitle}>Just one more thing...</h2>
          )}
        </div>
        
        <div>
          <div>
            <p className={styles.textContent}>
              {oldUsername 
                ? "We've recently updated our username guidelines to ensure a better experience for everyone."
                : "To get started, choose a username for your account."}
            </p>
            
            {oldUsername && (
              <div className={styles.currentUsername}>
                <p className={styles.labelText}>Your current username</p>
                <p className={styles.currentUsernameText}>{oldUsername}</p>
              </div>
            )}
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="username" className={styles.labelText}>
              Choose a new username
            </label>
            <input
              id="username"
              type="text"
              value={newUsername}
              onChange={(e) => {
                setNewUsername(e.target.value);
                setError('');
              }}
              className={styles.usernameInput}
              placeholder="Enter new username"
            />
            {error && (
              <p className={styles.errorText}>{error}</p>
            )}
          </div>

          <button
            onClick={handleSubmit}
            disabled={isChecking}
            className={styles.submitButton}
          >
            {isChecking ? 'Checking...' : 'Choose Username'}
          </button>

          <div className={styles.guidelinesContainer}>
            <p className={styles.guidelinesTitle}>Username Guidelines:</p>
            <ul className={styles.guidelinesList}>
              <li>30 characters maximum</li>
              <li>Letters, numbers, periods, and underscores only</li>
              <li>No special characters or emojis</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const validateUsername = (username) => {
    if (username === undefined || username === null) {
      return "Choose a username!";
    }

    const validationRules = [
      {
        test: (value) => value.length <= 30,
        message: "Username must be 30 characters or less"
      },
      {
        test: (value) => /^[a-zA-Z0-9._]*$/.test(value),
        message: "Username can only contain letters, numbers, periods, and underscores"
      },
      {
        test: (value) => !/[!@#$%^&*()+\-=[\]{};':"\\|,<>/?~`]/.test(value),
        message: "Username cannot contain symbols or punctuation marks"
      },
      {
        test: (value) => !/[\u{1F300}-\u{1F9FF}]/u.test(value),
        message: "Username cannot contain emojis"
      }
    ];

    for (const rule of validationRules) {
      if (!rule.test(username)) {
        return rule.message;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      try {
        const result = await getProfileData();
        setUserAndProfile(result);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserAndProfile();
  }, []);

  // Show nothing while loading
  if (isLoading) {
    return null;
  }

  const validationMessage = validateUsername(userAndProfile?.username);

  if (validationMessage === null || userAndProfile === null) {
    console.log("Showing modal with:", {
      validationMessage,
      userAndProfile
    });
    return ( 
      <BrowserRouter>
        {/* <SmartBanner /> */}
        <DeepLinkHandler />
        <Routes>
          <Route path="/custom-redirect" element={<CustomRedirect />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/ai/socrates" element={<AI />}></Route>
          <Route path="/ai" element={<AI />}></Route> 
          <Route path="/userstats" element={<UserStats />}></Route>
          <Route path="/settings" element={<SettingsPage />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
          <Route path="/auth/callback" element={<AuthCallback />}></Route>
          <Route path="/profile" element={<ProfilePage />}></Route>
          <Route path="/fact-check" element={<FactCheckPage />} />
          <Route path="/self-ai" element={<SelfAI />} />
          <Route path="/stored-debates/:debateId" element={<StoredDebate />} />
          <Route path="/bpai" element={<BPAiWrapper />}/>
          <Route path="/challenge" element={<SetupDebateRoom />} />
          <Route path ="/rooms" element = {<RoomsHome />} />
          <Route path="/debate/:roomId" element={<DebateRoom />} />
          <Route path="/checkout" element={<PricingPage />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    console.log("Showing modal with:", {
      validationMessage,
      userAndProfile
    });
    return (
      <UsernameValidationModal
        oldUsername={userAndProfile?.username}
        validationMessage={validationMessage}
      />
    );
  }
}

export default App;