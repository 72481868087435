import React, { useEffect, useState } from 'react';
import { Crown, X, Bot, Sparkles, Landmark, Users } from 'lucide-react';
import styles from '../styles/WelcomeGoldModal.module.css';

const WelcomeGoldModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const benefits = [
    {
      icon: <Bot className={styles.benefitIcon} size={24} />,
      title: "All Personas Unlocked",
      description: "Debate with every AI personality in our collection"
    },
    {
      icon: <Sparkles className={styles.benefitIcon} size={24} />,
      title: "Unlimited Evaluation",
      description: "Get detailed feedback on all your debates"
    },
    {
      icon: <Landmark className={styles.benefitIcon} size={24} />,
      title: "British Parliamentary",
      description: "Access our BP debate simulator"
    },
    {
      icon: <Crown className={styles.benefitIcon} size={24} />,
      title: "Premium Cosmetics",
      description: "Exclusive gold styling and animations"
    }
  ];

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        {/* Particles */}
        <div className={styles.particles}>
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className={styles.particle}
              style={{ animationDelay: `${i * 0.5}s` }}
            />
          ))}
        </div>

        {/* Close button */}
        <button onClick={onClose} className={styles.closeButton}>
          <X size={24} />
        </button>

        {/* Content */}
        <div className={styles.content}>
          {/* Header */}
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <Crown className={styles.crownIcon} size={32} />
              <h2 className={styles.title}>Welcome to Gold!</h2>
            </div>
            <p className={styles.subtitle}>
              You now have access to our premium features
            </p>
          </div>

          {/* Benefits grid */}
          <div className={styles.benefitsGrid}>
            {benefits.map((benefit, index) => (
              <div key={index} className={styles.benefitCard}>
                <div className={styles.benefitIconWrapper}>{benefit.icon}</div>
                <div className={styles.benefitContent}>
                  <h3 className={styles.benefitTitle}>{benefit.title}</h3>
                  <p className={styles.benefitDescription}>{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className={styles.footer}>
            <p className={styles.footerText}>
              Need to make changes to your plan? Visit your account settings.
            </p>
            <button
              onClick={onClose}
              className={styles.startButton}
            >
              Start Debating
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeGoldModal;