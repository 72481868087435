import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "../utils/debounce";
import { modes } from "../constants/debateModes";
import { personas } from "../constants/personas";
import {
  BLITZ_PROMPT,
  PERSONA_BLITZ_PROMPT,
  STANDARD_PROMPT_WITH_SEARCH,
  STANDARD_PROMPT_WITHOUT_SEARCH,
  DEBATER_SYSTEM_PROMPT,
  DEBATER_USER_PROMPT,
  CROSS_EXAMINER_ROLEPLAY_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_USER_PROMPT,
  CROSS_EXAMINER_ROLEPLAY_USER_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_STARTING_PROMPT,
  BLITZ_STARTING_PROMPT,
  DEBATE_ROOM_SUMMARY_PROMPT,
  DEBATE_ROOM_SUMMARY_PROMPT_TIE
} from "../constants/prompts";
import "../styles/AI.module.css";
import SetupPanel from "./SetupPanelAI";
import BpSetupPanel from "./BpSetupPanel";
import DebateArena from "../components/DebateArena";
import TopMenuBar from "../components/TopMenuBar";
import {
  streamChatCompletion,
  transcribeAudio as transcribeAudioAPI,
  generateSearchQueries,
  searchExa,
  processSearchResults,
  evaluateArgument,
  generateCxArg,
  extractCoreArguments,
  findDroppedArguments,
  generateDebateRFD
} from "../utils/api";
import {
  extractClaim,
  extractImpact,
  extractWarrant,
} from "../utils/crossExamUtils";
import {
  calculateDebateResult,
  calculateCumulativeScores,
  getDisplayScore
} from "../utils/scoreUtils"
import BadResponse from "../components/BadResponse";
import EvaluationReportCard from "../components/EvaluationReportCard";
import { getCurrentUserAndProfile, uploadDebate } from "../queries/Queries";

const useSynchronizedMessages = (initialMessages = []) => {
  const [messages, setMessages] = useState(initialMessages);
  const messagesRef = useRef(messages);

  const updateMessages = useCallback(async (updater) => {
    return new Promise((resolve) => {
      setMessages((prev) => {
        const newMessages = typeof updater === "function" ? updater(prev) : updater;
        messagesRef.current = newMessages;
        resolve(newMessages);
        return newMessages;
      });
    });
  }, []);

  return [messages, updateMessages, messagesRef];
};

const useSynchronizedEvaluations = (initialEvaluations = []) => {
  const [evaluations, setEvaluations] = useState(initialEvaluations);
  const evaluationsRef = useRef(evaluations);

  const updateEvaluations = useCallback(async (updater) => {
    return new Promise((resolve) => {
      setEvaluations((prev) => {
        const newEvaluations = typeof updater === "function" ? updater(prev) : updater;
        evaluationsRef.current = newEvaluations;
        resolve(newEvaluations);
        return newEvaluations;
      });
    });
  }, []);

  return [evaluations, updateEvaluations, evaluationsRef];
};

function AI() {
  const [topic, setTopic] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [side, setSide] = useState("for");
  const [examSide, setExamSide] = useState(null);
  const [debateStarted, setDebateStarted] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [messages, updateMessages, messagesRef] = useSynchronizedMessages([]);
  const [evaluations, updateEvaluations, evaluationsRef] = useSynchronizedEvaluations([]);
  const [input, setInput] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [mode, setMode] = useState("Blitz");
  const [currentRound, setCurrentRound] = useState(0);
  const [prepTimeLeft, setPrepTimeLeft] = useState(modes[mode].prepTime);
  const [debateTimeLeft, setDebateTimeLeft] = useState(modes[mode].debateTime);
  const [timerActive, setTimerActive] = useState(false);
  const [turnReady, setTurnReady] = useState(false);
  const [debateConcluded, setDebateConcluded] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [allSources, setAllSources] = useState([]);
  const [showEvaluationReport, setShowEvaluationReport] = useState(false);
  const [evaluationSummary, setEvaluationSummary] = useState("");
  const [globalDebateTime, setGlobalDebateTime] = useState(modes.Blitz.globalTime);
  const globalTimerRef = useRef(null);
  const hasRunAIFirstTurn = useRef(false);
  const mediaRecorderRef = useRef(null);
  const arenaRef = useRef(null);
  const timerRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [invalidAttempts, setInvalidAttempts] = useState(0);
  const [showBadResponse, setShowBadResponse] = useState(false);
  const [isValidResponse, setIsValidResponse] = useState(true);
  const [isInvalidated, setInvalidated] = useState(false);
  const [examArg, setExamArg] = useState("");
  const [isUnacceptable, setUnacceptable] = useState(false);
  const [currIsInvalid, setCurrIsInvalid] = useState(false);
  const [crossExaminationRole, setCrossExaminationRole] = useState("Examiner");
  const [divArg, setDivArg] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [stringMessages, setStringMessages] = useState("[]");
  const messageCountRef = useRef(0);
  const [currentDebateId, setCurrentDebateId] = useState(null);
  const [showAIFirstTurn, setShowAIFirstTurn] = useState(true);
  const [previousArguments, setPreviousArguments] = useState([]);
  const [droppedArgumentsMap, setDroppedArgumentsMap] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cumulativeScores, setCumulativeScores] = useState()
  const [debateResult, setDebateResult] = useState(1);
  const [evaluationRFD, setEvaluationRFD] = useState("");
  const [displayScore, setDisplayScore] = useState(null);

  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (evaluationSummary !== "") {
      const cumulativeScores = calculateCumulativeScores(evaluationsRef.current);
      const result = calculateDebateResult(cumulativeScores, side);
      const finalScore = getDisplayScore(cumulativeScores[cumulativeScores.length - 1])
      setDisplayScore(finalScore)
      
      // Generate RFD based on result
      const generateRFD = async () => {
        const roundByRoundData = evaluationsRef.current
          .map((e, index) => `Round ${index + 1}: ${e.side} - ${e.summary}`)
          .join("\n");
  
        const debateTranscript = messagesRef.current
          .map(msg => `${msg.role.toUpperCase()}: ${msg.content}`)
          .join("\n\n");
  
        const prompt = result === 0 
          ? DEBATE_ROOM_SUMMARY_PROMPT_TIE
          : DEBATE_ROOM_SUMMARY_PROMPT;
  
        const rfd = await generateDebateRFD(
          topic,
          roundByRoundData,
          debateTranscript,
          result === 0 ? 'tie' : result > 0 ? side : side === 'for' ? 'against' : 'for'
        );
        
        setEvaluationRFD(rfd);
  
        // Send final debate update with RFD
        await sendDebateWithLatestMessages(
          currentDebateId,
          mode,
          messagesRef.current,
          topic,
          side,
          examSide,
          selectedPersona ? selectedPersona.name : "AI",
          evaluationSummary,
          divArg,
          allSources,
          evaluationsRef.current,
          result,
          rfd,
          finalScore
        );
      };
  
      generateRFD();
    }
  }, [evaluationSummary]);

  useEffect(() => {
    const messagesString = JSON.stringify(
      messages.map((msg) => ({
        role: msg.role,
        content: msg.content,
      }))
    );
    setStringMessages(messagesString);
  }, [messages]);

  useEffect(() => {
    if (messages.length >= 1) {
      setShowAIFirstTurn(false);
    }
  });

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleSignOut = () => {
    setUserAndProfile(null);
  };

  useEffect(() => {
    if (currentRound >= modes[mode].rounds.length) {
      setDebateConcluded(true);
      setTimerActive(false);
      setTurnReady(false);
    }
  }, [currentRound, mode]);

  useEffect(() => {
    if (debateStarted && arenaRef.current) {
      const handleAnimationEnd = () => {
        setAnimationFinished(true);
      };
      arenaRef.current.addEventListener("animationend", handleAnimationEnd);

      return () => {
        if (arenaRef.current) {
          arenaRef.current.removeEventListener("animationend", handleAnimationEnd);
        }
      };
    }
  }, [debateStarted]);

  useEffect(() => {
    if (timerActive) {
      timerRef.current = setInterval(() => {
        // Global timer for Blitz and Cross-Examine
        if (mode === "Blitz" || mode === "Cross-Examine") {
          setGlobalDebateTime((prevTime) => {
            if (prevTime <= 0) {
              clearInterval(timerRef.current);
              setDebateConcluded(true);
              return 0;
            }
            return prevTime - 1;
          });
        }

        // Handle prep time and debate time for all modes
        if (!turnReady) {
          setPrepTimeLeft((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              setTurnReady(true);
              setDebateTimeLeft(modes[mode].debateTime);
              return 0;
            }
          });
        } else {
          setDebateTimeLeft((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              clearInterval(timerRef.current);
              setAutoSubmit(true);
              return 0;
            }
          });
        }
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [timerActive, turnReady, mode]);

  useEffect(() => {
    if (autoSubmit) {
      handleSubmit(null);
      setAutoSubmit(false);
    }
  }, [autoSubmit]);

  const sendDebateWithLatestMessages = async (
    debateId,
    mode,
    messages,
    topic,
    position,
    role,
    persona,
    summary,
    examArg,
    sources,
    latestEvaluations,
    debateResult = null,
    rfd = null,
    finalScore = null
  ) => {
    const newDebateId = await uploadDebate(
      debateId || currentDebateId,
      mode,
      messages,
      topic,
      position,
      role,
      persona,
      summary,
      examArg,
      sources,
      latestEvaluations,
      debateResult,
      rfd,
      finalScore
    );
  
    if (!debateId && !currentDebateId) {
      setCurrentDebateId(newDebateId);
    }
  
    return newDebateId;
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        setAudioBlob(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const transcribeAudio = async () => {
    if (!audioBlob) return;

    try {
      const transcription = await transcribeAudioAPI(audioBlob);
      setInput((prev) => prev + " " + transcription);
      setAudioBlob(null);
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  useEffect(() => {
    if (audioBlob) {
      transcribeAudio();
    }
  }, [audioBlob]);

  const handleStartDebate = async () => {
    setCurrentDebateId(null);

    if (!topic.trim()) {
      alert("Please enter a topic to start the debate.");
      return;
    }

    setDebateStarted(true);
    setDebateConcluded(false);
    setAnimationFinished(false);
    await updateMessages([]);
    await updateEvaluations([]);
    setEvaluationSummary("");

    let crossExamSide = "";
    let crossExamArg = "";

    if (mode === "Cross-Examine") {
      let initialTopic = topic;
      let initialExamArg = "";
      let initialUserRole = side;
      let initialAssistantRole = initialUserRole === "for" ? "against" : "for";
      crossExamSide = initialUserRole;

      let roleForArg = "";

      if (examSide === "Examiner") {
        roleForArg = initialAssistantRole;
      } else {
        roleForArg = initialUserRole;
      }

      initialExamArg = await generateCxArg(side, initialTopic);
      crossExamArg = initialExamArg;

      setExamArg(initialExamArg);
      setDivArg({
        claim: extractClaim(initialExamArg),
        warrant: extractWarrant(initialExamArg),
        impact: extractImpact(initialExamArg),
      });
    }

    const debateId = await sendDebateWithLatestMessages(
      null,
      mode,
      messagesRef.current,
      topic,
      side,
      examSide,
      selectedPersona ? selectedPersona.name : "AI",
      null,
      divArg,
      allSources,
      evaluationsRef.current
    );
    
    setCurrentDebateId(debateId);

    if (mode === "Cross-Examine") {
      setGlobalDebateTime(modes["Cross-Examine"].globalTime);
      if (examSide === "Examinee") {
        setTimeout(() => {
          handleAIFirstTurns(crossExamArg, crossExamSide, selectedPersona);
        }, modes["Cross-Examine"].prepTime * 1000);
      }
    } else if (mode === "Blitz") {
      setGlobalDebateTime(modes.Blitz.globalTime);
      setTurnReady(true);
    }

    setPrepTimeLeft(modes[mode].prepTime);
    setDebateTimeLeft(modes[mode].debateTime);
    setCurrentRound(0);
    setTimerActive(true);
    setDebateConcluded(false);
    setInvalidAttempts(0);
    setInvalidated(false);
    setUnacceptable(false);
  };

  useEffect(() => {
    if (debateStarted && mode === "Cross-Examine" && examSide === "Examinee" && examArg) {
      hasRunAIFirstTurn.current = true;
    }
  }, [debateStarted, mode, examSide, examArg]);

  useEffect(() => {
    if (!debateStarted) {
      hasRunAIFirstTurn.current = false;
    }
  }, [debateStarted]);

  const handleNextRound = () => {
    if (mode === "Standard") {
      setCurrentRound((prev) => prev + 1);
    }
    setTurnReady(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setTimerActive(true);
  };

  const handleEndPrep = debounce(() => {
    setTurnReady(true);
    setTimerActive(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setPrepTimeLeft(0);
  }, 250);

  function randomSide() {
    return Math.random() < 0.5 ? "for" : "against";
  }

  const letAIGoFirst = async () => {
    let assistantRole = side === "for" ? "against" : "for";
    await handleAIFirstTurns(null, assistantRole, personas[0], true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await handleAIFirstTurns(null, side, selectedPersona, false);
  };

  const handleAIFirstTurns = async (arg, userRole, persona, forUser) => {
    setIsStreaming(true);
    setTurnReady(false);

    let assistantRole = userRole === "for" ? "against" : "for";
    let systemPromptWithContext;
    let userPromptWithContext;
    let modeSpecificGuidelines;
    let fullResponse = "";

    try {
      const isFirstMessage = messages.length === 0;
      if (isFirstMessage) {
        if (mode === "Blitz") {
          if (selectedPersona) {
            modeSpecificGuidelines = PERSONA_BLITZ_PROMPT.replace(
              "{persona}",
              persona.name
            ).replace("{personaGuidelines}", persona.guidelines);
            userPromptWithContext = BLITZ_STARTING_PROMPT;
          } else {
            modeSpecificGuidelines = BLITZ_PROMPT;
            userPromptWithContext = BLITZ_STARTING_PROMPT;
          }
        } else if (mode === "Cross-Examination") {
          modeSpecificGuidelines = CROSS_EXAMINEE_ROLEPLAY_PROMPT.replace(
            "{argument}",
            arg
          );
          userPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_STARTING_PROMPT;
        }
      } else {
        const conversationHistory = messages
          .filter((msg) => msg.role !== "system")
          .map(
            (msg) => `${msg.role.split("-")[0].toUpperCase()}: ${msg.content}`
          )
          .join("\n\n");

        if (mode === "Blitz") {
          if (selectedPersona) {
            modeSpecificGuidelines = PERSONA_BLITZ_PROMPT.replace(
              "{persona}",
              persona.name
            ).replace("{personaGuidelines}", persona.guidelines);
          } else {
            modeSpecificGuidelines = BLITZ_PROMPT;
            userPromptWithContext = DEBATER_USER_PROMPT.replace(
              "{conversationHistory}",
              conversationHistory
            ).replace("{userMessage}", messages[messages.length - 1].content);
          }
        }
      }

      systemPromptWithContext = DEBATER_SYSTEM_PROMPT.replace(
        "{modeSpecificGuidelines}",
        modeSpecificGuidelines
      )
        .replace("{topic}", topic)
        .replace("{assistantRole}", assistantRole);

      const stream = await streamChatCompletion(
        systemPromptWithContext,
        userPromptWithContext
      );

      const reader = stream.getReader();
      const decoder = new TextDecoder();
      let prefix = forUser ? "user" : "assistant";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        let chunk = decoder.decode(value).toString();
        if (chunk.endsWith("[DONE]")) {
          chunk = chunk.slice(0, -6);
        }
        fullResponse += chunk;
        await updateMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (
            lastMessage &&
            lastMessage.role === `${prefix}-${userRole === "for" ? "against" : "for"}`
          ) {
            return [
              ...prev.slice(0, -1),
              { ...lastMessage, content: fullResponse },
            ];
          } else {
            return [
              ...prev,
              {
                role: `${prefix}-${userRole === "for" ? "against" : "for"}`,
                content: fullResponse,
              },
            ];
          }
        });
        if (chunk.endsWith("[DONE]")) {
          break;
        }
      }

      let currentMode = mode;
      const aiEvaluation = await evaluateArgument(
        topic,
        currentMode,
        isFirstMessage
          ? []
          : messagesRef.current.slice(-10).map((msg) => msg.content),
        examSide,
        fullResponse,
        isFirstMessage ? side : side === "for" ? "against" : "for"
      );

      await updateEvaluations((prev) => [
        ...prev,
        {
          ...aiEvaluation,
          side: isFirstMessage ? side : side === "for" ? "against" : "for",
        },
      ]);

      await sendDebateWithLatestMessages(
        currentDebateId,
        mode,
        messagesRef.current,
        topic,
        side,
        examSide,
        selectedPersona ? selectedPersona.name : "AI",
        null,
        divArg,
        null,
        evaluationsRef.current
      );

    } catch (error) {
      console.error("Error in AI's first turn:", error);
      throw error;
    } finally {
      setIsStreaming(false);
      setTurnReady(true);
      setTimerActive(true);
      setDebateTimeLeft(modes[mode].debateTime);
    }
  };

  const processMessage = async (content, previousMessage) => {
    const currentArguments = await extractCoreArguments(content);

    let droppedArguments = null;
    if (previousMessage?.arguments) {
      droppedArguments = await findDroppedArguments(
        previousMessage.arguments,
        content
      );
    }

    return {
      content,
      arguments: currentArguments,
      droppedArguments,
    };
  };

  const handleSubmit = debounce(async (e) => {
    if (e) e.preventDefault();
    if (debateConcluded || isSubmitting || isStreaming || !turnReady) return;

    setIsSubmitting(true);

    const userRole = side.toLowerCase();
    const assistantRole = userRole === "for" ? "against" : "for";

    const previousMessage = messages[messages.length - 1];
    const processedUserMessage = await processMessage(
      input || "(Skipped Turn)",
      previousMessage
    );

    const userMessage = {
      role: `user-${userRole.toLowerCase()}`,
      content: processedUserMessage.content,
      arguments: processedUserMessage.arguments,
      droppedArguments: processedUserMessage.droppedArguments,
    };

    await updateMessages((prev) => [...prev, userMessage]);

    setInput("");
    setIsStreaming(true);
    setTimerActive(false);
    setTurnReady(false);

    const userEvaluation = await evaluateArgument(
      topic,
      mode,
      messagesRef.current.slice(-10).map((msg) => msg.content),
      examSide,
      userMessage.content,
      userRole
    );

    await updateEvaluations((prev) => [...prev, { ...userEvaluation, side: userRole }]);

    await sendDebateWithLatestMessages(
      currentDebateId,
      mode,
      messagesRef.current,
      topic,
      side,
      examSide,
      selectedPersona ? selectedPersona.name : "AI",
      null,
      divArg,
      allSources,
      evaluationsRef.current
    );

    try {
      let systemPromptWithContext;
      let userPromptWithContext;
      let modeSpecificGuidelines;

      const conversationHistory = messagesRef.current
        .filter((msg) => msg.role !== "system")
        .map((msg) => `${msg.role.split("-")[0].toUpperCase()}: ${msg.content}`)
        .join("\n\n");

      if (mode === "Blitz") {
        if (selectedPersona) {
          modeSpecificGuidelines = PERSONA_BLITZ_PROMPT.replace(
            "{persona}",
            selectedPersona.name
          ).replace("{personaGuidelines}", selectedPersona.guidelines);
        } else {
          modeSpecificGuidelines = BLITZ_PROMPT;
        }
      } else if (mode === "Standard") {
        const searchQueries = await generateSearchQueries(
          topic,
          modes[mode].rounds[currentRound].name,
          userMessage.content
        );

        const searchResults = await Promise.all(
          searchQueries.map((query) => searchExa(query))
        );

        const processedResults = searchResults.flatMap(processSearchResults);

        if (processedResults.length > 0) {
          const topResults = processedResults
            .sort((a, b) => b.score - a.score)
            .slice(0, 3);
          const searchContext = topResults
            .map(
              (result, index) =>
                `[${index + 1}] ${result.title}: ${result.highlight}`
            )
            .join("\n\n");

          await new Promise((resolve) => {
            setAllSources((prev) => {
              const newSources = [
                ...prev,
                {
                  round: currentRound + 1,
                  sources: topResults.map((source) => ({
                    title: source.title,
                    url: source.url,
                  })),
                },
              ];
              resolve(newSources);
              return newSources;
            });
          });

          let roundDetails = modes[mode].rounds[currentRound].name;
          modeSpecificGuidelines = STANDARD_PROMPT_WITH_SEARCH.replace(
            "{searchContext}",
            searchContext
          ).replace("{round}", roundDetails);
        } else {
          modeSpecificGuidelines = STANDARD_PROMPT_WITHOUT_SEARCH;
        }
      }

      if (mode === "Cross-Examine") {
        const conversationHistory = messagesRef.current
          .filter((msg) => msg.role !== "system")
          .map(
            (msg) => `${msg.role.split("-")[0].toUpperCase()}: ${msg.content}`
          )
          .join("\n\n");

        if (examSide === "Examinee") {
          systemPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_PROMPT.replace(
            "{argument}",
            examArg
          );
          userPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_USER_PROMPT.replace(
            "{conversationHistory}",
            conversationHistory
          ).replace("{userMessage}", userMessage.content);
        } else {
          systemPromptWithContext = CROSS_EXAMINER_ROLEPLAY_PROMPT.replace(
            "{argument}",
            examArg
          );
          userPromptWithContext = CROSS_EXAMINER_ROLEPLAY_USER_PROMPT.replace(
            "{conversationHistory}",
            conversationHistory
          ).replace("{userMessage}", userMessage.content);
        }
      } else {
        systemPromptWithContext = DEBATER_SYSTEM_PROMPT.replace(
          "{topic}",
          topic
        )
          .replace("{assistantRole}", assistantRole)
          .replace("{modeSpecificGuidelines}", modeSpecificGuidelines);
        userPromptWithContext = DEBATER_USER_PROMPT.replace(
          "{conversationHistory}",
          conversationHistory
        ).replace("{userMessage}", userMessage.content);
      }

      const stream = await streamChatCompletion(
        systemPromptWithContext,
        userPromptWithContext
      );

      let isInvalid = false;
      let fullResponse = "";
      const reader = stream.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        let chunk = decoder.decode(value).toString();

        if (chunk.endsWith("[DONE]")) {
          chunk = chunk.slice(0, -6);
        }

        fullResponse += chunk;

        await updateMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage && lastMessage.role === `assistant-${assistantRole}`) {
            return [
              ...prev.slice(0, -1),
              { ...lastMessage, content: fullResponse },
            ];
          } else {
            return [
              ...prev,
              { role: `assistant-${assistantRole}`, content: fullResponse },
            ];
          }
        });

        if (chunk.endsWith("[DONE]")) {
          break;
        }

        if (
          fullResponse.startsWith("INVALID DEBATE PROMPT") ||
          fullResponse.startsWith('"INVALID DEBATE PROMPT')
        ) {
          isInvalid = true;
          setIsValidResponse(false);
          setInvalidAttempts((prev) => {
            const newAttempts = prev + 1;
            if (newAttempts >= 3) {
              handleEndDebate();
            }
            return newAttempts;
          });

          await updateMessages((prev) => prev.slice(0, -2));
          setCurrIsInvalid(true);
          await updateEvaluations((prev) => prev.slice(0, -1));
          setEvaluationSummary("");
          setShowBadResponse(true);
          break;
        }

        if (fullResponse.startsWith("UNACCEPTABLE DEBATE PROMPT")) {
          isInvalid = true;
          setIsValidResponse(false);
          await updateMessages((prev) => prev.slice(0, -2));
          await updateEvaluations((prev) => prev.slice(0, -1));
          setCurrIsInvalid(true);
          setShowBadResponse(true);
          setUnacceptable(true);
          handleEndDebate();
          break;
        }
      }

      if (!isInvalid) {
        const processedAIMessage = await processMessage(fullResponse, userMessage);

        await updateMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          return [
            ...prev.slice(0, -1),
            {
              ...lastMessage,
              content: fullResponse,
              arguments: processedAIMessage.arguments,
              droppedArguments: processedAIMessage.droppedArguments,
            },
          ];
        });

        const llmEvaluation = await evaluateArgument(
          topic,
          mode,
          messagesRef.current.slice(-11, -1).map((msg) => msg.content),
          examSide,
          fullResponse,
          assistantRole
        );

        await updateEvaluations((prev) => [
          ...prev,
          { ...llmEvaluation, side: assistantRole },
        ]);

        await sendDebateWithLatestMessages(
          currentDebateId,
          mode,
          messagesRef.current,
          topic,
          side,
          examSide,
          selectedPersona ? selectedPersona.name : "AI",
          null,
          divArg,
          allSources,
          evaluationsRef.current
        );
      }
    } catch (error) {
      console.error("Error in debate process:", error);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 500);
      setIsStreaming(false);
      if (isValidResponse) {
        if (mode === "Blitz" || mode === "Cross-Examine") {
          setDebateTimeLeft(modes[mode].debateTime);
          handleNextRound();
        } else if (currentRound < modes[mode].rounds.length - 1) {
          handleNextRound();
        } else {
          setDebateConcluded(true);
          setTimerActive(false);
          setTurnReady(false);
        }
      } else {
        setTurnReady(true);
        setTimerActive(true);
        setDebateTimeLeft(modes[mode].debateTime);
      }
    }
  }, 500, { leading: true, trailing: false });

  const handleEndDebate = () => {
    setDebateConcluded(true);
    if (invalidAttempts === 3) {
      setInvalidated(true);
    }
    setTurnReady(false);
    setTimerActive(false);
    if (globalTimerRef.current) {
      clearInterval(globalTimerRef.current);
    }
    setGlobalDebateTime(0);
    setInput("");
    setPrepTimeLeft(0);
    setDebateTimeLeft(0);
    
    setShowEvaluationReport(true);
  };

  return (
    <div className="App">
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      <div className="main-content">
        {!debateStarted && (
          <SetupPanel
            mode={mode}
            setMode={setMode}
            topic={topic}
            setTopic={setTopic}
            side={side}
            setSide={setSide}
            debateStarted={debateStarted}
            handleStartDebate={handleStartDebate}
            setSelectedPersona={setSelectedPersona}
            crossExaminationRole={crossExaminationRole}
            setCrossExaminationRole={setCrossExaminationRole}
            examSide={examSide}
            setExamSide={setExamSide}
          />
        )}
        {debateStarted && (
          <DebateArena
            arenaRef={arenaRef}
            animationFinished={animationFinished}
            examSide={examSide}
            side={side}
            examArg={examArg}
            topic={topic}
            mode={mode}
            currentRound={currentRound}
            messages={messagesRef.current}
            chatBoxRef={chatBoxRef}
            turnReady={turnReady}
            debateConcluded={debateConcluded}
            prepTimeLeft={prepTimeLeft}
            debateTimeLeft={debateTimeLeft}
            input={input}
            divArg={divArg}
            setInput={setInput}
            isStreaming={isStreaming}
            isRecording={isRecording}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            handleEndPrep={handleEndPrep}
            startRecording={startRecording}
            stopRecording={stopRecording}
            handleEndDebate={handleEndDebate}
            allSources={allSources}
            globalDebateTime={globalDebateTime}
            evaluations={evaluationsRef.current}
            showEvaluationReport={showEvaluationReport}
            showAIFirstTurn={showAIFirstTurn}
            setShowAIFirstTurn={setShowAIFirstTurn}
            handleAIFirstTurn={letAIGoFirst}
            persona={selectedPersona}
          />
        )}
        {showBadResponse && (
          <BadResponse
            unacceptable={isUnacceptable}
            invalidated={isInvalidated}
            attemptsLeft={invalidAttempts}
            onClose={() => setShowBadResponse(false)}
          />
        )}
        {showEvaluationReport && (
          <EvaluationReportCard
            isDbRoom={false}
            fromStoredDebate={false}
            evaluations={evaluationsRef.current}
            topic={topic}
            personaName={selectedPersona ? selectedPersona.name : "AI"}
            onClose={() => {
              setShowEvaluationReport(false);
              updateEvaluations([]);
              setEvaluationSummary("");
              updateMessages([]);
              setSelectedPersona("");
              setTopic("");
              setDebateStarted(false);
              setAnimationFinished(false);
              setAllSources("");
            }}
            onHide={() => setShowEvaluationReport(false)}
            messages={messagesRef.current}
            userSide={side}
            summary={evaluationSummary}
            setSummary={setEvaluationSummary}
            id={currentDebateId}
            cumulativeScores={cumulativeScores}
            setCumulativeScores={setCumulativeScores}
            rfd={evaluationRFD}
            displayScore={displayScore}
          />
        )}
      </div>
    </div>
  );
}

export default AI;