import React, { useState, useEffect } from 'react';
import styles from '../styles/CountdownTimer.module.css';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const calculateTimeToNextNoon = () => {
      const now = new Date();
      // Convert current time to EST
      const estTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      
      // Create noon time for today
      const noonToday = new Date(estTime);
      noonToday.setHours(12, 0, 0, 0);
      
      // If it's past noon, set target to tomorrow noon
      if (estTime > noonToday) {
        noonToday.setDate(noonToday.getDate() + 1);
      }
      
      // Calculate difference in seconds
      return Math.floor((noonToday - estTime) / 1000);
    };

    // Initial calculation
    setTimeLeft(calculateTimeToNextNoon());

    // Update every second
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          // Recalculate when we hit zero
          return calculateTimeToNextNoon();
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div className={styles.countdownTimer}>
      <div className={styles.timeDisplay}>
        {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </div>
      <div className={styles.label}>
        until next free evaluation
      </div>
    </div>
  );
};

export default CountdownTimer;