import { supabase } from './supabaseClient';

const STRIPE_PRICES = {
  GOLD_MONTHLY: 'price_1QWvp0P7c3gOr4wGGzGzbseg',
  GOLD_YEARLY: 'price_1QX9swP7c3gOr4wGkmNriD2F',
  SINGLE_EVALUATION: 'price_1QWu0EP7c3gOr4wG48ikapF7'
};

export const createSubscriptionCheckout = async (priceId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session) {
      throw new Error('Please login first');
    }

    const response = await fetch(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-subscription-checkout`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
          'apikey': process.env.REACT_APP_SUPABASE_API_KEY
        },
        body: JSON.stringify({
          priceId,
          userId: session.user.id,
          email: session.user.email
        }),
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to create checkout session');
    }

    const { url } = await response.json();
    return url;

  } catch (error) {
    console.error('Error creating subscription checkout:', error);
    throw error;
  }
};

export const createEvaluationCheckout = async (debateId) => {
  try {
    // Validate debate ID
    if (!debateId) {
      throw new Error('Debate ID is required');
    }

    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session) {
      throw new Error('Please login first');
    }

    if (!session.user?.id || !session.user?.email) {
      throw new Error('User information is incomplete');
    }

    // Log the data being sent for debugging
    console.log('Checkout data:', {
      debateId,
      userId: session.user.id,
      email: session.user.email,
      priceId: STRIPE_PRICES.SINGLE_EVALUATION
    });

    const response = await fetch(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-evaluation-checkout`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
          'apikey': process.env.REACT_APP_SUPABASE_ANON_KEY
        },
        body: JSON.stringify({
          priceId: STRIPE_PRICES.SINGLE_EVALUATION,
          userId: session.user.id,
          email: session.user.email,
          debateId: debateId
        }),
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to create checkout session');
    }

    const { url } = await response.json();
    return url;

  } catch (error) {
    console.error('Error creating evaluation checkout:', {
      message: error.message,
      debateId,
    });
    throw error;
  }
};

export const handleUpgrade = async (isYearly) => {
  try {
    const priceId = isYearly ? STRIPE_PRICES.GOLD_YEARLY : STRIPE_PRICES.GOLD_MONTHLY;
    const checkoutUrl = await createSubscriptionCheckout(priceId);
    window.location.href = checkoutUrl;
  } catch (error) {
    console.error('Error handling upgrade:', error);
    // Provide a more specific error message
    if (error.message === 'Please login first') {
      alert('Please login to upgrade your subscription');
    } else {
      alert('An error occurred while processing your upgrade. Please try again.');
    }
  }
};

export const createPortalSession = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session) {
      throw new Error('Please login first');
    }

    const response = await fetch(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-portal-session`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
          'apikey': process.env.REACT_APP_SUPABASE_API_KEY
        }
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to create portal session');
    }

    const { url } = await response.json();
    return url;

  } catch (error) {
    console.error('Error creating portal session:', error);
    throw error;
  }
};